import React, {useContext} from 'react';
import {FormikProps} from 'formik';
import {media} from 'src/styles/utils';
import styled from 'styled-components';
import AlertTriangleIcon from 'src/svgs/AlertTriangleIcon';
import {AuthRegionFlag} from 'src/components/shared/AuthRegionFlag';
import {
  AuthRegionMap,
  HYPERCARE_SUPPORT_LINK,
  LOGIN_BOX_DESCRIPTION_SWITCH_REGION_TEXT,
  LOGIN_BOX_DESCRIPTION_TEXT,
  LOGIN_PAGE_CURRENT_REGION_TITLE,
  LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_SUB_INFO,
  LOGIN_PAGE_LOCKED_ACCOUNT_CONTACT_US,
  LOGIN_PAGE_LOCKED_ACCOUNT_HELP_TEXT,
  LOGIN_PAGE_LOCKED_ACCOUNT_MESSAGE,
  UNITED_STATES,
  UNITED_STATES_ABBREVIATION,
} from 'src/constants/strings';
import {LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_TITLE} from '../../../constants/strings';
import {ChangeRegionViewModel} from 'src/components/ChangeRegion/ChangeRegionViewModel';
import HypercareAuthRegionContext from 'src/contexts/HypercareLoginCoordinatorContext';
import {getParsedAuthRegion} from '../../../utils/localStorageHandler';

const WarningWrapper = styled.div`
  margin: 0 0 12px 0;
  display: flex;
  text-align: left;
  padding: 1em 0.5em;
  opacity: 0.8;
  border-radius: 3px;
  background-color: rgba(255, 62, 85, 0.2);
`;

const WarningIconWrapper = styled.div`
  margin-right: 1em;
  margin-left: 0.5em;
  padding-top: 4px;
  svg {
    width: 32px;
    height: 32px;
    color: ${(props) => props.theme.watermelon};
  }
`;

const WarningDetailTitle = styled.div`
  font-size: 14px;
  line-height: 1.5em;
  font-weight: bold;
  color: ${(props) => props.theme.blackFontColor};
  a {
    color: ${(props) => props.theme.chatTeal};
    &:hover {
      filter: opacity(0.8);
    }
  }
`;

const WarningDetailSubInfo = styled(WarningDetailTitle)`
  font-weight: normal;
  font-size: 12px;
`;

const LoginFormHeaderSection = styled.div`
  margin-bottom: 40px;
  width: 100%;
`;

const HeaderTitle = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  text-align: center;
  color: ${(props) => props.theme.blackFontColor};
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    height: 32px;
    width: 32px;
    margin-left: 8px;
    pointer-events: none;
    margin-right: 8px;
    margin-bottom: 0 !important;
  }
  ${media.sm`
    font-weight: 600;
    justify-content: left;
    font-size: 21px;
    display: flex;
    flex-wrap: wrap;
    
  `}
`;

const HeaderContext = styled.div`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  user-select: none;
  font-size: 18px;
  line-height: 1.5;
  color: #4a4a4a;
  ${media.sm`
    font-size: 16px;
    display: block;
  `}
  span {
    color: ${(props) => props.theme.chatTeal};
    cursor: pointer;
    padding: 4px 0;
    &:hover {
      text-decoration: underline;
    }
  }
`;

interface Values {
  username: string;
  password: string;
}

interface Props {
  isAccountLocked: boolean;
  loginAttempts?: number;
  formikProps?: FormikProps<Values>;
}

const NewLoginFormHeader = ({isAccountLocked, loginAttempts, formikProps}: Props) => {
  const currentAuthRegion = getParsedAuthRegion();
  const {setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);

  return (
    <>
      <LoginFormHeaderSection>
        <HeaderTitle>
          {LOGIN_PAGE_CURRENT_REGION_TITLE} <AuthRegionFlag currentRegion={currentAuthRegion} size="large" />
          {currentAuthRegion === UNITED_STATES_ABBREVIATION ? UNITED_STATES : AuthRegionMap[currentAuthRegion]}
        </HeaderTitle>
        <HeaderContext>
          {LOGIN_BOX_DESCRIPTION_TEXT(currentAuthRegion, AuthRegionMap)}
          <span onClick={() => setChangeRegionModalOpenStatus(true)}>{LOGIN_BOX_DESCRIPTION_SWITCH_REGION_TEXT}</span>
        </HeaderContext>
      </LoginFormHeaderSection>
      {!isAccountLocked && loginAttempts > 2 && (
        <WarningWrapper>
          <WarningIconWrapper>
            <AlertTriangleIcon />
          </WarningIconWrapper>
          <div>
            <WarningDetailTitle>{LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_TITLE}</WarningDetailTitle>
            <WarningDetailSubInfo>{LOGIN_PAGE_INCORRECT_LOGIN_DETAIL_SUB_INFO}</WarningDetailSubInfo>
          </div>
        </WarningWrapper>
      )}
      {isAccountLocked && (
        <WarningWrapper>
          <WarningIconWrapper>
            <AlertTriangleIcon />
          </WarningIconWrapper>
          <div>
            <WarningDetailTitle>
              <span>{LOGIN_PAGE_LOCKED_ACCOUNT_MESSAGE}</span>
              <a href={HYPERCARE_SUPPORT_LINK} rel="noopener noreferrer" target="_blank">
                <br />
                {LOGIN_PAGE_LOCKED_ACCOUNT_CONTACT_US}
              </a>
              <span>{LOGIN_PAGE_LOCKED_ACCOUNT_HELP_TEXT}</span>
            </WarningDetailTitle>
          </div>
        </WarningWrapper>
      )}
    </>
  );
};

export default NewLoginFormHeader;
