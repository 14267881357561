import React from 'react';
import {toast} from 'react-toastify';
import {FullOrganizationMember} from 'src/types';
import AlertModal from 'src/pages/MessengerPage/messenger/messages-layout/message-template/AlertModal';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import styled from 'styled-components';
import createNewChat from 'src/utils/messengerHelper/createNewChat';
import {MESSENGER} from 'src/constants/routerPathName';
import PagerMessageTabView from 'src/pages/LocatingPage/locating/profile-section/PagerMessageTabView';
import {makeStyles, Typography} from '@material-ui/core';
import 'src/components/index.css';
import client from 'src/apollo';
import SendSMSMessage from 'src/gql/mutation/SendSMSMessage';
import {LEARN_MORE, SMS_MODAL_CONTENT} from 'src/constants/strings';
import {StyledHrefLink} from 'src/styles/styled-components/PopoverMenuComponents';

const StyledToastWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 16px;
`;

const useStyles = makeStyles((_) => ({
  alertTitle: {
    marginBottom: '24px',
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  paperWidthMd: {
    maxWidth: '50vw',
  },
}));

interface Props {
  user: FullOrganizationMember | any;
  address?: string;
  showSMSModal: boolean;
  setShowSMSModal: (boolean) => void;
}

const SMSModal = ({user, address, showSMSModal, setShowSMSModal}: Props) => {
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSubmitting, setSubmitting] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showDiscardChanges, setShowDiscardChanges] = React.useState(false);
  const [pagerMessage, setPagerMessage] = React.useState('');
  const classes = useStyles({});

  const discardSubmit = () => {
    setShowDiscardChanges(false);
    setShowSMSModal(false);
    setErrorMessage('');
    setIsLoading(false);
    setSubmitting(false);
    toast('SMS discarded', {
      className: 'toast-discard',
      autoClose: 2000,
    });
  };

  async function tryCreateNewChat(isAlternative: boolean) {
    try {
      const newChatId = isAlternative ? await createNewChat([user]) : await createNewChat([user.workStatusProxy]);

      if (!newChatId) throw new Error('failed');
      window.routerHistory.push(`/${MESSENGER}/${newChatId}`);
    } catch (e) {
      console.error(e);
      toast.error('Failed to start new chat, please check your internet connection and try again');
    }
  }

  const handleSubmit = async () => {
    if (pagerMessage.length === 0) {
      setErrorMessage('Field cannot be empty!');
      setSubmitting(true);
    } else {
      setIsLoading(true);

      try {
        const sendPagerMessageResult = await client.mutate({
          mutation: SendSMSMessage,
          variables: {
            userId: user.id,
            address: address,
            message: pagerMessage,
          },
        });

        if (!sendPagerMessageResult?.data?.self?.sms) throw new Error('failed');
        toast(
          <StyledToastWrapper>
            <CheckCircleIcon />
            <>
              {`
              SMS successfully sent to ${user.firstName} ${user.lastName}
            `}
            </>
            {user.memberStatus !== 'shell' && (
              <span onClick={() => tryCreateNewChat(true)}>
                <u>View</u>
              </span>
            )}
          </StyledToastWrapper>,
          {
            className: 'toast-message',
            autoClose: 5000,
          },
        );
      } catch (e) {
        console.error(e);
        toast.error('Failed to send page, please check your internet connection and try again.');
      }
      setShowSMSModal(false);
      setIsLoading(false);
    }
  };

  return (
    <>
      <AlertModal
        width="xs"
        title={`Send ${user.firstName} ${user.lastName} an SMS at ${address}`}
        titleFontSize="21px"
        subtitle=""
        closeAlertModal={() => setShowDiscardChanges(true)}
        isAlertModalVisible={showSMSModal}
        alertModalButtons={[
          {
            type: 'secondary',
            buttonLabel: 'Cancel',
            onClickHandler: () => setShowDiscardChanges(true),
            id: 'cancel-btn',
          },
          {
            type: 'primary',
            buttonLabel: isLoading ? 'Sending...' : 'Send',
            onClickHandler: () => handleSubmit(),
            disabled: errorMessage !== '' || isSubmitting || isLoading,
            id: 'send-btn',
          },
        ]}
        modalContent={
          <>
            <Typography classes={{root: classes.alertTitle}}>
              {SMS_MODAL_CONTENT}
              <StyledHrefLink
                target="_blank"
                href="https://support.hypercare.com/hc/en-us/articles/21198484295821-Why-can-t-I-share-health-information-via-pagers-or-SMS-"
              >
                <span>{LEARN_MORE}</span>
              </StyledHrefLink>
            </Typography>
            <PagerMessageTabView
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              setPagerMessage={setPagerMessage}
              isSubmitting={isSubmitting}
              setSubmitting={setSubmitting}
              inputType="alphanumeric_pager"
            />
          </>
        }
      />
      {showDiscardChanges && (
        <AlertModal
          id="discard-modal"
          width="sm"
          title="Discard unsaved message?"
          titleFontSize="21px"
          subtitle="Your message will not be saved. Are you sure you want to discard this?"
          closeAlertModal={() => setShowDiscardChanges(false)}
          isAlertModalVisible={showDiscardChanges}
          alertModalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Nevermind',
              onClickHandler: () => setShowDiscardChanges(false),
              id: 'discard-nevermind',
            },
            {
              type: 'primary',
              buttonLabel: 'Discard',
              onClickHandler: () => discardSubmit(),
              id: 'discard-confirm',
            },
          ]}
        />
      )}
    </>
  );
};

export default SMSModal;
