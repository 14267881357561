import ApiHelper from '../../../../api';
import {AuthPayloadDTO, AuthSSOVendors, RequestVerificationPayload} from '../../../../types';
import {AccountStatus, OrganizationAccountsCacheData, OrgLoginMethods} from '../../../../types/sta';
import {checkIfUserHasLoginScope} from '../../../../utils/userHelper/userUtils';
import {
  CURRENT_SELECTED_ORGANIZATION,
  EMAIL,
  ORG_URL,
  ORGANIZATION_ACCOUNTS_DATA,
  SSO_VENDOR,
} from '../../../../constants/storageKeys';
import {TOO_MANY_CHALLENGES} from '../../../../constants/networkError';
import {localStorageService} from '../../../../services/localStorageService';
import {fi} from 'date-fns/locale';

export const AccountSelectionViewModel = () => {
  const getBasicUser = async (email: string, orgUrl: string) => {
    const res = await ApiHelper.PublicEndpoints.fetchBasicUser(email, orgUrl);

    if (res?.data?.user.__typename === 'UserNotFound') {
      return 'UserNotFound';
    }

    if (res.data) {
      return res.data.user;
    }
  };

  const handleLoginToClickedAccount = async (accountInformation: AuthPayloadDTO) => {
    const {organization, user} = accountInformation;
    const orgUrl = organization.url;
    const currentSavedAccountData =
      localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    const userAddress = user.email || user.username || '';

    window.localStorage.setItem(ORG_URL, orgUrl);
    window.localStorage.setItem(EMAIL, userAddress || '');
    localStorage.setItem(CURRENT_SELECTED_ORGANIZATION, JSON.stringify(organization));

    window.localStorage.removeItem('flowStep');

    if (
      currentSavedAccountData?.savedOrganizations.some(
        (org) => org.user.id === accountInformation.user.id && org.accessToken,
      )
    ) {
      return {
        screen: 'Login',
      };
    }

    const basicUser = await getBasicUser(userAddress, orgUrl);

    if (!basicUser || basicUser === 'UserNotFound') {
      return {error: 'UserNotFound'};
    }

    if (basicUser.memberStatus === AccountStatus.BLOCKED || basicUser.memberStatus === AccountStatus.ARCHIVED) {
      return {error: 'Inactive account'};
    }

    const firstSSOProfile = basicUser.ssoProfiles?.[0];

    const enteredEmailDomain = (accountInformation.user.email || accountInformation.user.username || '').split('@')[1];

    const checkAccountForSSOScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.SSO);
    const checkAccountForOTPScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.OTP);
    const checkAccountForPasswordScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.PASSWORD);

    const getOrgByDomain = await ApiHelper.PrivateEndpoints.getOrganizationByDomain({
      domain: enteredEmailDomain,
    });

    if ('error' in getOrgByDomain) {
      return {error: 'No matched account'};
    }

    let checkForSSOEmailOfOrg;

    if (getOrgByDomain.data.organizationForDomain) {
      checkForSSOEmailOfOrg = accountInformation.organization.id === getOrgByDomain.data.organizationForDomain.id;
    }

    if (checkForSSOEmailOfOrg) {
      if (checkAccountForSSOScope) {
        if (firstSSOProfile?.auth0Id && firstSSOProfile?.ssoVendor) {
          localStorage.setItem(SSO_VENDOR, firstSSOProfile?.ssoVendor);
          if (firstSSOProfile?.ssoVendor === AuthSSOVendors.WORKOS) {
            await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(
              firstSSOProfile?.auth0Id,
              firstSSOProfile?.ssoVendor,
            );
            return;
          } else {
            return {auth0Id: firstSSOProfile?.auth0Id, address: ''};
          }
        }
      }
    }

    if (checkAccountForOTPScope || checkAccountForPasswordScope) {
      if (basicUser.memberStatus === AccountStatus.INACTIVE) {
        const challengeId = await requestAddressOTP({address: userAddress, type: 'email'});
        if (typeof challengeId === 'string') {
          window.routerHistory.push(
            `/signup?email=${userAddress?.replace(
              '+',
              '%2b',
            )}&challengeId=${challengeId}&orgUrl=${orgUrl}&step=2&activateShellAccountFlow=true&orgName=${
              accountInformation.organization.name
            }`,
          );
        } else {
          return challengeId;
        }
      } else {
        if (checkAccountForOTPScope && checkAccountForPasswordScope) {
          const startRequestOTPFlow = await requestAddressOTP({address: userAddress, type: 'email'});

          if (typeof startRequestOTPFlow === 'string') {
            return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow, isMethodSwitchable: true};
          } else {
            return startRequestOTPFlow;
          }
        }

        if (checkAccountForOTPScope) {
          const startRequestOTPFlow = await requestAddressOTP({address: userAddress, type: 'email'});

          if (typeof startRequestOTPFlow === 'string') {
            return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow};
          } else {
            return startRequestOTPFlow;
          }
        }

        if (checkAccountForPasswordScope) {
          return {screen: OrgLoginMethods.PASSWORD};
        }
      }
    } else {
      if (!firstSSOProfile) {
        return {error: 'Account doesnt not belong to an organization'};
      }

      if (checkAccountForOTPScope && firstSSOProfile?.domain === enteredEmailDomain) {
        if (firstSSOProfile?.auth0Id && firstSSOProfile?.ssoVendor) {
          localStorage.setItem(SSO_VENDOR, firstSSOProfile?.ssoVendor);
          if (firstSSOProfile?.ssoVendor === AuthSSOVendors.WORKOS) {
            await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(
              firstSSOProfile?.auth0Id,
              firstSSOProfile?.ssoVendor,
            );
            return;
          } else {
            return {auth0Id: firstSSOProfile?.auth0Id, address: ''};
          }
        }
      } else {
        return {
          error: 'No supported login methods',
        };
      }
    }
  };

  const requestAddressOTP = async ({address, type}: RequestVerificationPayload) => {
    const res = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA({address, type});

    if (res && res?.data?.response) {
      const challengeId: string = res.data.response.challengeId;
      return challengeId;
    } else {
      if (res && res?.data?.errors[0].name === TOO_MANY_CHALLENGES) {
        return {
          error: 'Too many challenges, please wait before starting another challenge',
        };
      } else {
        return {
          error: 'Failed to request validation code, please check your internet connection and try again',
        };
      }
    }
  };

  return {handleLoginToClickedAccount};
};
