import client from 'src/apollo';
import ApiHelper from 'src/api';
import {toast} from 'react-toastify';
import {FormikProps} from 'formik';
import {emailValidationSchema, organizationNameSchema, inviteCodeSchema} from 'src/utils/validations';
import GetUserOrganizationsQuery from 'src/gql/query/GetUserOrganizations';
import trySwitchOrganization from 'src/utils/organizationHelper/trySwitchOrganization';
import {
  VALIDATION_TOKEN_EXPIRED,
  ORG_NAME_ALREADY_EXIST,
  NO_ORG_FOR_EMAIL,
  INVITE_CODE_DRAINED,
  INVITE_CODE_INVALID,
  INVITE_CODE_EXPIRED,
  INVITE_CODE_NOT_FOUND,
  INVITE_ALREADY_USED_CODE,
  TOO_MANY_CHALLENGES,
  MAX_ADDRESS_VERIFY_ATTEMPS,
  CHALLENGE_TIME_EXPIRED,
  CHALLENGE_NOT_FOUND,
  INVALID_OTP,
  TOO_MANY_FAILED_ATTEMPTS,
  UNKNOWN_ERROR_CODE,
  FAILED_REQUEST_CODE,
  FAILED_RESEND_CODE,
  DOMAIN_BOUND_ADDRESS,
  CHALLENGE_TIME_EXPIRED_MESSAGE,
  CHALLENGE_NOT_FOUND_MESSAGE,
  INVALID_OTP_MESSAGE,
} from 'src/constants/networkError';
import {
  VerifyAddressValidationPayload,
  UserOrganizationSwitcherPayload,
  UserAddress,
  UserOrganizationScopeResult,
  RequestVerificationPayload,
} from 'src/types';
import sleep from 'src/utils/sleep';
import {IS_FINISHING_CREATE_ORGANIZATION, IS_JOIN_ORG_FROM_ONBOARDING} from 'src/constants/storageKeys';
import {MESSENGER} from '../../../constants/routerPathName';
import convertTypeToReadbleText from 'src/utils/locating/convertAddressTypeToText';
interface IntendedOrganization {
  image: string | null;
  name: string;
  id: string;
  sites: any;
}

interface CustomProps {
  activeStep: number;
  validationCodeArray: string[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setValidationErrorMsg: (msg: string) => void;
  setJoinOrganizationErrMsg: (msg: string) => void;
  intendedOrganization: IntendedOrganization;
  setIntendedOrganization: (org: any) => void;
  setLoading: (isLoading: boolean) => void;
  setAddressVerified: (isVerified: boolean) => void;
  isJoinUsingInviteCode: boolean;
  challengeId: string;
  setChallengeId: (boolean) => void;
}

class JoinOrganizationStepController {
  private static isCreatingNewOrg: boolean = false;

  private static joinOrganizationAgreementLinks = {
    termsOfServiceUrl: null,
    privacyPolicyUrl: null,
  };

  public static setJoinOrganizationAgreementLinks({termsOfServiceUrl, privacyPolicyUrl}) {
    this.joinOrganizationAgreementLinks = {
      termsOfServiceUrl,
      privacyPolicyUrl,
    };
  }

  public static getJoinOrganizationAgreementLinks() {
    return this.joinOrganizationAgreementLinks;
  }

  public static setCreatingNewOrgFlag(bool: boolean) {
    this.isCreatingNewOrg = bool;
  }

  public static getCreatingNewOrgFalg(): boolean {
    return this.isCreatingNewOrg;
  }

  public static processEmail(customProps: CustomProps, formikProps: FormikProps<any>) {
    const {
      values,
      touched,
      // handleSubmit,
      setFieldError,
      setFieldTouched,
    } = formikProps;

    const {setActiveStep, setAddressVerified, setLoading, setIntendedOrganization, setChallengeId} = customProps;

    const requesteAddressVerification = async (): Promise<'skip' | 'noskip'> => {
      const requestVerificationPayload: RequestVerificationPayload = {
        address: values.email,
        type: 'email',
      };
      const verifyAddressResult = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA(
        requestVerificationPayload,
      );
      if (verifyAddressResult?.data?.response) {
        setChallengeId(verifyAddressResult?.data.response?.challengeId);
      }
      if (verifyAddressResult?.data.errors) {
        const response = verifyAddressResult?.data.errors[0];

        if (response.name === TOO_MANY_CHALLENGES) {
          throw {
            errors: [response.message],
          };
        } else if (response.name) {
          throw {
            errors: [response.message],
          };
        }
        throw {
          errors: ['Failed to verify code, please check your internet connection and try again'],
        };
      }
      return Promise.resolve('noskip');
    };

    emailValidationSchema
      .validate(values.email)
      .then(async () => {
        setLoading(true);

        const fetchOrganiationResult = await ApiHelper.PublicEndpoints.fetchOrganizationByEmail(values.email);

        if (fetchOrganiationResult && fetchOrganiationResult.success) {
          const {organizationForEmail} = fetchOrganiationResult.data;
          const {termsOfServiceUrl, privacyPolicyUrl} = organizationForEmail;

          this.setJoinOrganizationAgreementLinks({
            termsOfServiceUrl,
            privacyPolicyUrl,
          });

          // check if already joined the organization
          const {data} = await client.query<UserOrganizationScopeResult>({
            query: GetUserOrganizationsQuery,
          });
          const {organizations} = data.me;
          const isOrgExistAlready = organizations.find((org) => org.id === organizationForEmail.id);
          if (isOrgExistAlready)
            throw {
              errors: ['You are already part of this organization'],
            };
          // check if email in use/verified
          const requesteAddressVerificationResult = await requesteAddressVerification();
          setIntendedOrganization(organizationForEmail as IntendedOrganization);
          this.setCreatingNewOrgFlag(false);
          setLoading(false);
          if (requesteAddressVerificationResult === 'skip') {
            setActiveStep(2);
          } else {
            setActiveStep(1);
          }
        } else if (
          fetchOrganiationResult.error &&
          fetchOrganiationResult.error.response &&
          fetchOrganiationResult.error.response.data &&
          fetchOrganiationResult.error.response.data.errors &&
          fetchOrganiationResult.error.response.data.errors[0].code === NO_ORG_FOR_EMAIL
        ) {
          // check if email in use/verified
          const requesteAddressVerificationResult = await requesteAddressVerification();
          this.setCreatingNewOrgFlag(true);
          setLoading(false);
          if (requesteAddressVerificationResult === 'skip') {
            setActiveStep(2);
          } else {
            setActiveStep(1);
          }
        } else {
          const response = fetchOrganiationResult.error.response;
          if (!response)
            throw {
              errors: [FAILED_REQUEST_CODE],
            };
          const errorObj = response.data.errors[0];
          switch (errorObj.code) {
            default:
              throw {errors: [UNKNOWN_ERROR_CODE]};
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (!touched.email) setFieldTouched('email', true);
        setFieldError('email', err.errors[0]);
      });
  }

  public static processInviteCode(customProps: CustomProps, formikProps: FormikProps<any>) {
    const {setActiveStep, setLoading, setIntendedOrganization} = customProps;

    const {values, touched, setFieldError, setFieldTouched} = formikProps;

    inviteCodeSchema
      .validate(values.inviteCode)
      .then(async () => {
        setLoading(true);
        const fetchOrganiationResult = await ApiHelper.PublicEndpoints.fetchOrganizationByInviteCode(values.inviteCode);

        if (fetchOrganiationResult && fetchOrganiationResult.success) {
          const {organizationForInviteCode} = fetchOrganiationResult.data;
          const {termsOfServiceUrl, privacyPolicyUrl} = organizationForInviteCode;

          this.setJoinOrganizationAgreementLinks({
            termsOfServiceUrl,
            privacyPolicyUrl,
          });

          // check if already joined the organization
          const {data} = await client.query({
            query: GetUserOrganizationsQuery,
          });
          const {organizations} = data.me;
          const isOrgExistAlready = organizations.find((org) => org.id === organizationForInviteCode.id);
          if (isOrgExistAlready)
            throw {
              errors: ['You are already part of this organization'],
            };
          // check if email in use/verified
          setIntendedOrganization(organizationForInviteCode as IntendedOrganization);
          this.setCreatingNewOrgFlag(false);
          setLoading(false);
          setActiveStep(2);
        } else {
          const response = fetchOrganiationResult.error.response;
          if (!response)
            throw {
              errors: ['Failed to verify the Organization Code, please check your internet connection and try again'],
            };
          const errorObj = response.data.errors[0];
          switch (errorObj.code) {
            case INVITE_CODE_NOT_FOUND:
              throw {
                errors: ['Organization code does not exist, please check your code and try again'],
              };
            case INVITE_CODE_INVALID:
              throw {
                errors: ['Inappropriate organization code for joining organization'],
              };
            case INVITE_CODE_DRAINED:
              throw {
                errors: ['There are no more invites remaining for this organization code'],
              };
            case INVITE_CODE_EXPIRED:
              throw {
                errors: ['Organization code is expired'],
              };
            default:
              throw {errors: ['Failed to verify the organization code']};
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (!touched.inviteCode) setFieldTouched('inviteCode', true);
        setFieldError('inviteCode', err.errors[0]);
      });
  }

  public static stepController(customProps: CustomProps, formikProps: FormikProps<any>) {
    const {values} = formikProps;

    const {
      activeStep,
      setActiveStep,
      setAddressVerified,
      isJoinUsingInviteCode,
      setLoading,
      validationCodeArray,
      setValidationErrorMsg,
      challengeId,
    } = customProps;

    switch (activeStep) {
      case 0:
        isJoinUsingInviteCode
          ? this.processInviteCode(customProps, formikProps)
          : this.processEmail(customProps, formikProps);

        break;

      case 1:
        (async () => {
          try {
            setValidationErrorMsg('');
            setLoading(true);
            const verificationCode = validationCodeArray.reduce((acc, current) => acc + current);

            const result = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(
              challengeId,
              verificationCode,
            );
            if (result && result.data.response) {
              const addAddressResult = await ApiHelper.PrivateEndpoints.addUserAddress(challengeId);

              if (
                addAddressResult?.data.data.selfMutation.addAddress.address ||
                addAddressResult?.data.data.selfMutation.addAddress.__typename === DOMAIN_BOUND_ADDRESS
              ) {
                setLoading(false);
                setAddressVerified(true);
                setActiveStep(2);
              } else {
                throw {
                  errors: [addAddressResult?.data.data.selfMutation.addAddress.message],
                };
              }
            } else {
              const errorObj = result?.data.errors[0].name;
              switch (errorObj) {
                case MAX_ADDRESS_VERIFY_ATTEMPS:
                  throw {
                    errors: [TOO_MANY_FAILED_ATTEMPTS],
                  };
                case CHALLENGE_TIME_EXPIRED:
                  throw {
                    errors: [CHALLENGE_TIME_EXPIRED_MESSAGE],
                  };
                case CHALLENGE_NOT_FOUND:
                  throw {
                    errors: [CHALLENGE_NOT_FOUND_MESSAGE],
                  };
                case INVALID_OTP:
                  throw {
                    errors: [INVALID_OTP_MESSAGE],
                  };
                default:
                  throw {
                    errors: [UNKNOWN_ERROR_CODE],
                  };
              }
            }
          } catch (err) {
            setLoading(false);
            setValidationErrorMsg(err.errors[0]);
          }
        })();
        break;

      case 2:
        (() => {
          const isCreatingNewOrganization = this.getCreatingNewOrgFalg();
          // create organization
          if (isCreatingNewOrganization) {
            this.handleCreateOrganization({
              customProps,
              formikProps,
            });
          } else {
            // join organization
            this.handleJoinOrganization({
              customProps,
              formikProps,
            });
          }
        })();
    }
  }

  public static resendValidationCode = async ({email, setLoading, setValidationErrorMsg, setChallengeId}) => {
    try {
      setLoading(true);
      const requestVerificationPayload: RequestVerificationPayload = {
        address: email,
        type: 'email',
      };
      const result = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA(requestVerificationPayload);
      if (result && result?.data?.response) {
        setChallengeId(result.data.response.challengeId);
        setLoading(false);
        toast.success(`Successfully resent verification code`);
      }
      if (result?.data.errors) {
        const response = result?.data.errors[0];

        if (response.name === TOO_MANY_CHALLENGES) {
          throw {
            errors: [response.message],
          };
        } else if (response.name) {
          throw {
            errors: [response.message],
          };
        }
        throw {
          errors: [FAILED_RESEND_CODE],
        };
      }
    } catch (err) {
      setLoading(false);
      setValidationErrorMsg(err.errors[0]);
    }
  };

  public static async handleCreateOrganization({customProps, formikProps}) {
    const {values, touched, setFieldError, setFieldTouched} = formikProps;

    const {setLoading} = customProps;

    organizationNameSchema
      .validate(values.organizationName)
      .then(async () => {
        setLoading(true);

        const emailDomain = values.email.split('@')[1];

        const result = await ApiHelper.PrivateEndpoints.createOrganization({
          name: values.organizationName,
          domain: emailDomain,
        });

        if (result && result.success) {
          const newOrg = result.data.createNewOrganization;
          const newOrganizationOption = {
            name: newOrg.name,
            image: newOrg.image,
            type: 'Organization',
            organizationId: newOrg.id,
          } as UserOrganizationSwitcherPayload;

          // wait for potential replication delay
          await sleep(2000);

          sessionStorage.setItem(IS_JOIN_ORG_FROM_ONBOARDING, '1');
          sessionStorage.setItem(IS_FINISHING_CREATE_ORGANIZATION, '1');
          trySwitchOrganization(newOrganizationOption);
          window.location.assign(`/${MESSENGER}/home`);
        } else {
          const response = result.error.response;
          if (!response)
            throw {
              errors: ['Failed to create organization, please check your internet connection and try again'],
            };
          const errorObj = response.data.errors[0];
          switch (errorObj.code) {
            case ORG_NAME_ALREADY_EXIST:
              throw {
                errors: [`Organization already exists for your institution's email`],
              };
            case VALIDATION_TOKEN_EXPIRED:
              throw {
                errors: ['Verification code expired, please go back to previous step and request a new code'],
              };
            default:
              throw {
                errors: ['Unknown error occurred, failed to create organization'],
              };
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        if (!touched.organizationName) setFieldTouched('organizationName', true);
        setFieldError('organizationName', err.errors[0]);
      });
  }

  public static async handleJoinOrganization({customProps, formikProps}) {
    const {setLoading, intendedOrganization, setJoinOrganizationErrMsg, isJoinUsingInviteCode} = customProps;

    const {values} = formikProps;

    try {
      setJoinOrganizationErrMsg('');
      setLoading(true);

      const result = isJoinUsingInviteCode
        ? await ApiHelper.PrivateEndpoints.requestJoinOrganizationViaInviteCode(values.inviteCode)
        : await ApiHelper.PrivateEndpoints.requestJoinOrganization({
            organizationId: intendedOrganization.id,
          });

      if (result && result.success) {
        const newOrganizationOption = {
          name: intendedOrganization.name,
          image: intendedOrganization.image,
          type: 'Organization',
          organizationId: intendedOrganization.id,
        } as UserOrganizationSwitcherPayload;

        // wait for potential replication delay
        await sleep(2000);

        setLoading(false);

        sessionStorage.setItem(IS_JOIN_ORG_FROM_ONBOARDING, '1');
        trySwitchOrganization(newOrganizationOption, Boolean(this.joinOrganizationAgreementLinks.termsOfServiceUrl));
      } else {
        const response = result.error.response;
        if (!response)
          throw {
            errors: ['Failed to join organization, please check your internet connection and try again'],
          };
        const errorObj = response.data.errors[0];
        switch (errorObj.code) {
          case INVITE_ALREADY_USED_CODE:
            throw {
              errors: ['You have already used this organization code in the past'],
            };
          default:
            throw {
              errors: ['Unknown error occurred, failed to join organization'],
            };
        }
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      setJoinOrganizationErrMsg(err.errors[0]);
    }
  }
}

export default JoinOrganizationStepController;
