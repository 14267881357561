import {OrganizationAccountsCacheData} from '../../types/sta';
import {localStorageService} from '../../services/localStorageService';
import {AUTH_INFO} from '../../constants/storageKeys';
import {AuthPayload} from '../../types';

export const getCurrentLoggedInAccount = (cachedData: OrganizationAccountsCacheData | null) => {
  const authInfo = localStorageService.getItem<AuthPayload>(AUTH_INFO);
  return cachedData?.savedOrganizations.find(
    (account) => account.user.id === cachedData?.selectedAccountUserId && authInfo?.user.id === account.user.id,
  );
};

export const checkForLoggedOutAccounts = (cachedData: OrganizationAccountsCacheData | null) => {
  if (!cachedData) {
    return false;
  }
  return cachedData?.savedOrganizations.some((account) => !account.accessToken);
};
