import * as React from 'react';
import {Redirect} from 'react-router-dom';
import {AuthContext} from 'src/auth/AuthProvider';
import LoadingDiv from 'src/components/LoadingDiv';
import {AuthSSOVendors, LoginViaSSO, StaSSOLogin} from 'src/types';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import sleep from '../../utils/sleep';
import {ACCOUNT_DISCOVERY_FLOW_STEP, EMAIL, ORG_URL, SSO_VENDOR} from '../../constants/storageKeys';
import {IsFeatureFlagEnabled} from '../../utils/FeatureFlagManager';
import {FeatureFlagResult} from '../../utils/FeatureFlags';
import {localStorageService} from '../../services/localStorageService';
import {LoginErrorSSOModal} from 'src/auth/LoginErrorSSOModal';

import {AccountDiscoveryViewModel} from '../LoginPage/sta/view-models/AccountDiscoveryViewModel';

interface Props {
  isLoggedIn: boolean;
  loginViaSSO: LoginViaSSO;
  auth0props: Auth0ContextInterface;
  staSSOLogin: StaSSOLogin;
}

const SSORedirectLoadingPage = ({isLoggedIn, loginViaSSO, auth0props, staSSOLogin}: Props) => {
  const {isAuthenticated, getAccessTokenSilently, logout} = auth0props;
  const STAFlag = !IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  const {handleAccountDiscoveryAfterSSO} = AccountDiscoveryViewModel();

  React.useEffect(() => {
    const getSSOVendor = localStorage.getItem(SSO_VENDOR) || '';
    const flowStep = localStorage.getItem('flowStep') || '';
    const orgUrl = localStorage.getItem(ORG_URL) || '';
    const email = localStorage.getItem(EMAIL) || '';

    const getAccessToken = async () => {
      if (isAuthenticated && getSSOVendor === AuthSSOVendors.AUTH0) {
        getAccessTokenSilently()
          .then(async (token) => {
            STAFlag ? await staSSOLogin(token, getSSOVendor, orgUrl, email) : await loginViaSSO(token, getSSOVendor);
          })
          .catch(async (err) => {
            console.error(err);

            await sleep(3000);
            logout({federated: true});
          });
      } else {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code') || '';

        switch (flowStep) {
          case ACCOUNT_DISCOVERY_FLOW_STEP:
            return handleAccountDiscoveryAfterSSO(code, getSSOVendor, email);
          case 'findMyOrg':
            return staSSOLogin(code, getSSOVendor, orgUrl, email);
          default:
            return staSSOLogin(code, getSSOVendor, orgUrl, email);
        }
      }
    };

    getAccessToken();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // if (isLoggedIn) return <Redirect to="/messenger" />;
  return <LoadingDiv />;
};

export default () => (
  <AuthContext.Consumer>
    {({isLoggedIn, loginViaSSO, auth0props, staSSOLogin}) => {
      const urlParams = new URLSearchParams(window.location.search);
      const urlErrorDescription = urlParams.get('error_description');
      if (urlErrorDescription) {
        return <LoginErrorSSOModal description={urlErrorDescription} />;
      }
      return (
        <SSORedirectLoadingPage
          staSSOLogin={staSSOLogin}
          auth0props={auth0props}
          isLoggedIn={isLoggedIn}
          loginViaSSO={loginViaSSO}
        />
      );
    }}
  </AuthContext.Consumer>
);
