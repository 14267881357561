import React from 'react';
import {HelperBox} from 'src/microfrontend/styled/login.styled';
import {StyledAnchor} from 'src/microfrontend/styled/login.styled';

export const ContactUsFooter = () => {
  return (
    <HelperBox>
      <StyledAnchor href="https://www.hypercare.com/contact" rel="noopener noreferrer" target="_blank">
        Having some issues?
        <span>Contact us</span>
      </StyledAnchor>
    </HelperBox>
  );
};
