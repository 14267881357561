import React, {useContext, useEffect, useState} from 'react';

import {AccountSelectionView} from './login/AccountSelectionView';
import {FindUserOrganizationView} from './login/FindUserOrganizationView';
import {AuthContext} from '../../../auth';
import {Auth0ContextInterface} from '@auth0/auth0-react';
import {AuthPayloadDTO, Login, STALogin} from '../../../types';
import {
  DEBUG,
  DESIRED_ROUTE,
  ENCODED_DATA_FROM_MOBILE_CLIENTS,
  INTRO_DONE,
  ORGANIZATION_ACCOUNTS_DATA,
} from '../../../constants/storageKeys';
import {Redirect} from 'react-router-dom';
import HypercareLogoSVG from '../../../svgs/HypercareLogoSVG';
import {CurrentSelectedOrganization} from '../../../microfrontend/types/login.types';
import {LoginEmailAddressView} from '../../../microfrontend/login/LoginEmailAddressView';
import {LoginPasswordView} from '../../../microfrontend/login/LoginPasswordView';
import {OrganizationViewModel} from './view-models/OrganizationViewModel';
import {
  CurrentLoginStep,
  OrganizationAccountsCacheData,
  OrganizationAccountsCacheDataFromMobile,
  OrgLoginMethods,
} from '../../../types/sta';
import {LoginOTPView} from '../../../microfrontend/login/LoginOTPView';
import {LoginPageContainer, LoginPageHypercareLogoContainer} from '../styled/login.styled';
import {AccountDiscoveryCoordinator} from './accountDiscovery/AccountDiscoveryCoordinator';
import ChangeRegionModal from '../../../components/ChangeRegion/ChangeRegionModal';
import {LOGIN} from '../../../constants/strings';
import HypercareAuthRegionContext from '../../../contexts/HypercareLoginCoordinatorContext';
import {localStorageService} from '../../../services/localStorageService';
import {AccountDiscoveryViewModel} from './view-models/AccountDiscoveryViewModel';
import {EXISTING_ACCOUNTS_PARAM} from '../../../constants/queryParams';
import {AUTH_INTRO} from '../../../constants/routerPathName';
import {toast} from 'react-toastify';

interface ILoginPageCoordinatorProps {
  isLoggedIn: boolean;
  auth0props: Auth0ContextInterface;
  login: Login;
  STALogin: STALogin;
}

const LoginPageCoordinator = ({isLoggedIn, auth0props, login, STALogin}: ILoginPageCoordinatorProps) => {
  const [currentStep, setCurrentStep] = useState<CurrentLoginStep>(CurrentLoginStep.STEP_1);
  const [currentSelectedOrg, setCurrentSelectedOrg] = useState<CurrentSelectedOrganization>({
    loginMethods: [],
    imageUrl: 'https://hypercare.github.io/public-uploads-prod/organization_logos/hypercare_icon_v2-01.png',
    url: '',
    name: 'SickKids',
    id: '',
  });

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [OTPError, setOTPError] = useState('');

  const [isResendingOTP, setIsResendingOTP] = useState(false);
  const [challengeId, setChallengeId] = useState('');
  const [currentEmail, setCurrentEmail] = useState('');

  const [isPasswordLoading, setIsPasswordLoading] = useState(false);
  const [isOTPLoading, setIsOTPLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [isLoginMethodSwitchable, setIsLoginMethodSwitchable] = useState(false);

  const {changeRegionModalOpenStatus, setChangeRegionModalOpenStatus} = useContext(HypercareAuthRegionContext);
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const [savedAccountsData, setSavedAccountsData] = useState<AuthPayloadDTO[]>(currentCacheData?.savedOrganizations);

  const {submitEmailAddress, submitOTP, submitPassword, handleOTPLogin} = OrganizationViewModel();
  const {handleSaveAccountsQueryParamToCache, handleResendOTP} = AccountDiscoveryViewModel();

  const handleShowAccountDiscoveryFlow = () => {
    setCurrentStep(CurrentLoginStep.STEP_6);
  };

  const encodedDataFromMobile = localStorageService.getItem<OrganizationAccountsCacheDataFromMobile>(
    ENCODED_DATA_FROM_MOBILE_CLIENTS,
  );

  useEffect(() => {
    if (!savedAccountsData?.length || currentCacheData?.savedOrganizations.length === 0) {
      handleShowAccountDiscoveryFlow();
    } else {
      setCurrentStep(CurrentLoginStep.STEP_1);
    }
  }, [savedAccountsData, changeRegionModalOpenStatus]);

  useEffect(() => {
    if (encodedDataFromMobile) {
      handleSaveAccountsQueryParamToCache(encodedDataFromMobile);
      localStorage.setItem(INTRO_DONE, '1');
      return;
    }

    if (window.localStorage.getItem(INTRO_DONE) !== '1') {
      window.routerHistory.push(`/${AUTH_INTRO}`);
    }
  }, []);

  // useEffect(() => {
  //   if (currentCacheData?.savedOrganizations.length === 1) {
  //     const accInfo = currentCacheData?.savedOrganizations[0];
  //     accInfo?.accessToken &&
  //       STALogin(accInfo?.organization, accInfo, accInfo?.user.email || accInfo?.user.username || '');
  //   }
  // }, [currentCacheData]);

  // if (isLoggedIn) {
  //   const desiredRoute = sessionStorage.getItem(DESIRED_ROUTE);
  //   return <Redirect to={desiredRoute ? desiredRoute : '/messenger/home'} />;
  // }

  const handleGoToPreviousStep = () => {
    setEmailError('');
    setPasswordError('');
    setIsEmailLoading(false);
    setIsPasswordLoading(false);

    if (savedAccountsData?.length > 0) {
      setCurrentStep(CurrentLoginStep.STEP_1);
    } else {
      setCurrentStep(CurrentLoginStep.STEP_6);
    }
  };

  const showDebugMenu = () => {
    window.routerHistory.replace(`/${DEBUG}`);
  };

  const handleSubmitPassword = async (value: string) => {
    setIsPasswordLoading(true);
    const res = await submitPassword(currentSelectedOrg.url || '', currentEmail, value);

    if (res?.error) {
      setPasswordError(res.error);
      setIsPasswordLoading(false);
    }
    if (res?.data) {
      STALogin(currentSelectedOrg, res.data, currentEmail);
    }
  };

  const handleSubmitEmailAddress = async (value: string) => {
    setIsEmailLoading(true);
    setCurrentEmail(value);

    const res = await submitEmailAddress(value, currentSelectedOrg);

    if (res && 'error' in res) {
      setEmailError(res.error);
    }
    if (res && 'auth0Id' in res) {
      loginWithAuth0(res.auth0Id);
    }
    setIsEmailLoading(false);

    switch (res.screen) {
      case OrgLoginMethods.OTP:
        setCurrentStep(CurrentLoginStep.STEP_5);
        res.challengeId && setChallengeId(res.challengeId);
        res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
        return;
      case OrgLoginMethods.PASSWORD:
        setCurrentStep(CurrentLoginStep.STEP_4);
        res.isMethodSwitchable && setIsLoginMethodSwitchable(true);
        return;
      case OrgLoginMethods.LOGIN:
        STALogin(currentSelectedOrg, res.data, currentEmail);
        break;
      default:
        break;
    }
  };

  const loginWithAuth0 = (auth0Id: string) => {
    auth0props.loginWithRedirect({
      connection: auth0Id,
      scope: 'openid profile email',
    });
  };

  const handleSubmitOTP = async (value: string) => {
    setIsOTPLoading(true);
    const res = await submitOTP(challengeId, value, currentSelectedOrg.url || '');

    if (res?.error) {
      setOTPError(res.error);
    }

    if (res.data) {
      STALogin(currentSelectedOrg, res.data, currentEmail);
    }
    setIsOTPLoading(false);
  };

  const resendOTPCode = async (email: string) => {
    const res = await handleResendOTP(email);

    if (res?.challengeId) {
      setChallengeId(res.challengeId);
      toast.success(`Successfully resent verification code`);
    }

    if (res?.error) {
      toast.error('Unable to resend verification code');
    }
  };

  const handleIsLoginMethodSwitchableClick = async (type: string) => {
    if (type !== 'password') {
      setCurrentStep(CurrentLoginStep.STEP_4);
    } else {
      const ers = await handleOTPLogin(currentEmail);
      setCurrentStep(CurrentLoginStep.STEP_5);
    }
  };
  const showEmailAddressView = () => {
    return (
      <LoginEmailAddressView
        handleGoBack={handleGoToPreviousStep}
        handleNext={handleSubmitEmailAddress}
        organization={currentSelectedOrg}
        error={emailError}
        isLoading={isEmailLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
      />
    );
  };

  const showPasswordView = () => {
    return (
      <LoginPasswordView
        handleGoBack={handleGoToPreviousStep}
        handleNext={handleSubmitPassword}
        organization={currentSelectedOrg}
        error={passwordError}
        isLoading={isPasswordLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
      />
    );
  };

  const showOTPView = () => {
    return (
      <LoginOTPView
        handleGoBack={handleGoToPreviousStep}
        handleNext={handleSubmitOTP}
        organization={currentSelectedOrg}
        resend={resendOTPCode}
        isResending={isResendingOTP}
        error={OTPError}
        currentSelectedEmail={currentEmail}
        isLoading={isOTPLoading}
        isLoginMethodSwitchable={isLoginMethodSwitchable}
        handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
      />
    );
  };

  const handleShowEmailView = () => {
    setCurrentStep(CurrentLoginStep.STEP_3);
  };
  const handleShowPasswordView = (isMethodSwitchable?: boolean) => {
    setCurrentStep(CurrentLoginStep.STEP_4);
    isMethodSwitchable && setIsLoginMethodSwitchable(true);
  };

  const handleShowOtpView = (isMethodSwitchable?: boolean) => {
    setCurrentStep(CurrentLoginStep.STEP_5);
    isMethodSwitchable && setIsLoginMethodSwitchable(true);
  };

  const showAccountDiscovery = () => {
    return <AccountDiscoveryCoordinator showFindMyOrgView={() => setCurrentStep(CurrentLoginStep.STEP_2)} />;
  };

  const renderCurrentStep = (currentStep: CurrentLoginStep) => {
    switch (currentStep) {
      case CurrentLoginStep.STEP_1:
        return (
          <AccountSelectionView
            handleNextStep={handleShowAccountDiscoveryFlow}
            STALogin={STALogin}
            showOtpView={handleShowOtpView}
            showPasswordView={handleShowPasswordView}
            loginWithAuth0={loginWithAuth0}
            setEmail={setCurrentEmail}
            setCurrentChallengeId={setChallengeId}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            savedAccountsData={savedAccountsData}
            setSavedAccountsData={setSavedAccountsData}
          />
        );
      case CurrentLoginStep.STEP_2:
        return (
          <FindUserOrganizationView
            handleGoBack={handleGoToPreviousStep}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            handleShowEmailView={handleShowEmailView}
            handleShowPasswordView={handleShowPasswordView}
          />
        );
      case CurrentLoginStep.STEP_3:
        return showEmailAddressView();
      case CurrentLoginStep.STEP_4:
        return showPasswordView();
      case CurrentLoginStep.STEP_5:
        return showOTPView();
      case CurrentLoginStep.STEP_6:
        return showAccountDiscovery();

      default:
        return (
          <AccountSelectionView
            savedAccountsData={savedAccountsData}
            handleNextStep={handleShowAccountDiscoveryFlow}
            STALogin={STALogin}
            showOtpView={handleShowOtpView}
            showPasswordView={handleShowPasswordView}
            loginWithAuth0={loginWithAuth0}
            setCurrentChallengeId={setChallengeId}
            setEmail={setCurrentEmail}
            setCurrentSelectedOrg={setCurrentSelectedOrg}
            setSavedAccountsData={setSavedAccountsData}
          />
        );
    }
  };

  return (
    <LoginPageContainer>
      <LoginPageHypercareLogoContainer onClick={showDebugMenu}>
        <HypercareLogoSVG />
      </LoginPageHypercareLogoContainer>
      {renderCurrentStep(currentStep)}
      {changeRegionModalOpenStatus && (
        <ChangeRegionModal
          isOpen={changeRegionModalOpenStatus}
          setIsOpen={setChangeRegionModalOpenStatus}
          currentScreen={LOGIN}
          setSavedAccountsData={setSavedAccountsData}
        />
      )}
    </LoginPageContainer>
  );
};

export const HypercareLoginPageCoordinator = () => {
  return (
    <AuthContext.Consumer>
      {({isLoggedIn, auth0props, login, STALogin}) => (
        <LoginPageCoordinator STALogin={STALogin} isLoggedIn={isLoggedIn} auth0props={auth0props} login={login} />
      )}
    </AuthContext.Consumer>
  );
};
