import React from 'react';

export const BlackInfoIcon = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00016 15.1439C4.31816 15.1439 1.3335 12.1592 1.3335 8.47721C1.3335 4.79521 4.31816 1.81055 8.00016 1.81055C11.6822 1.81055 14.6668 4.79521 14.6668 8.47721C14.6668 12.1592 11.6822 15.1439 8.00016 15.1439ZM8.66683 5.81055C8.66683 6.17874 8.36835 6.47721 8.00016 6.47721C7.63197 6.47721 7.3335 6.17874 7.3335 5.81055C7.3335 5.44236 7.63197 5.14388 8.00016 5.14388C8.36835 5.14388 8.66683 5.44236 8.66683 5.81055ZM8.00016 7.14388C8.36835 7.14388 8.66683 7.44236 8.66683 7.81055V11.1439C8.66683 11.5121 8.36835 11.8105 8.00016 11.8105C7.63197 11.8105 7.3335 11.5121 7.3335 11.1439V7.81055C7.3335 7.44236 7.63197 7.14388 8.00016 7.14388Z"
      fill="#4A4A4A"
    />
  </svg>
);
