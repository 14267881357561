import React from 'react';
import {LinearProgress} from '@material-ui/core';
import {SignupProgress} from './SignUpProgress';
import {StyledStepContainer, StyledInner, StyledDotContainer} from '../styled/signup.styled';

interface ISignupContainerProps {
  children?: React.ReactNode;
  progress?: boolean;
  currentStep: number;
  showOverflow?: boolean;
}

const SignupContainer = (props: ISignupContainerProps) => {
  return (
    <StyledStepContainer>
      <StyledInner showOverflow={props.showOverflow}>{props.children}</StyledInner>
      <StyledDotContainer>
        {props.progress ? (
          <LinearProgress color="secondary" />
        ) : (
          <SignupProgress value={(props.currentStep * 100) / 7} />
        )}
      </StyledDotContainer>
    </StyledStepContainer>
  );
};

export default SignupContainer;
