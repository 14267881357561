import {LoginForm} from './LoginForm';
import React, {useState} from 'react';
import * as Yup from 'yup';
import {
  LOGIN_PAGE_PASSWORD_VIEW_DESCRIPTION,
  LOGIN_PAGE_PASSWORD_VIEW_EMPTY_PASSWORD,
  LOGIN_PAGE_PASSWORD_VIEW_SEARCH_BAR_LABEL,
  LOGIN_PAGE_PASSWORD_VIEW_SEARCH_BAR_PLACEHOLDER,
  LOGIN_PAGE_PASSWORD_VIEW_TITLE,
} from '../../constants/strings';
import {CurrentSelectedOrganization, Organization} from '../types/login.types';

interface ILoginPasswordViewProps {
  handleGoBack: () => void;
  handleNext: (password: string) => void;
  organization?: CurrentSelectedOrganization;
  error: string;
  isLoading: boolean;
  isLoginMethodSwitchable: boolean;
  handleIsLoginMethodSwitchableClick: (type: string) => Promise<void>;
}

export const LoginPasswordView = ({
  handleGoBack,
  handleNext,
  organization,
  error,
  isLoading,
  isLoginMethodSwitchable,
  handleIsLoginMethodSwitchableClick,
}: ILoginPasswordViewProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <LoginForm
      initialValues={{password: ''}}
      validationSchema={Yup.object().shape({
        password: Yup.string().required(LOGIN_PAGE_PASSWORD_VIEW_EMPTY_PASSWORD),
      })}
      onSubmit={async (values, actions) => {
        //actions.setSubmitting(true);
        await handleNext(values.password);
        //   actions.setSubmitting(false);
      }}
      handleGoBack={handleGoBack}
      organization={organization}
      type={'password'}
      showPassword={showPassword}
      setShowPassword={setShowPassword}
      showEndAdornment={true}
      error={error}
      isLoading={isLoading}
      isLoginMethodSwitchable={isLoginMethodSwitchable}
      handleIsLoginMethodSwitchableClick={handleIsLoginMethodSwitchableClick}
    >
      {{
        title: LOGIN_PAGE_PASSWORD_VIEW_TITLE,
        description: LOGIN_PAGE_PASSWORD_VIEW_DESCRIPTION(organization?.name),
        placeholder: LOGIN_PAGE_PASSWORD_VIEW_SEARCH_BAR_PLACEHOLDER,
        label: LOGIN_PAGE_PASSWORD_VIEW_SEARCH_BAR_LABEL,
        name: 'password',
        dataTestId: 'passwordInput',
      }}
    </LoginForm>
  );
};
