import gql from 'graphql-tag';
import {ActivateShellAccountUserDetails} from '../../../types/sta';

export interface ActivateShellAccountMutationInputPayload {
  organizationUrl: string;
  challengeId: string;
  userDetails: ActivateShellAccountUserDetails;
}

export interface ActivateShellAccountMutationResponse {
  data: {
    activateAccount: {
      id: string;
      __typename: string;
    };
  };
}

export const ACTIVATE_SHELL_ACCOUNT_MUTATION = gql`
  mutation ActivateAccount($challengeId: String!, $organizationUrl: URL!, $userDetails: SignupUserDetails!) {
    activateAccount(challengeId: $challengeId, organizationUrl: $organizationUrl, userDetails: $userDetails) {
      ... on SelfUser {
        id
      }
      ... on Error {
        __typename
        message
      }
    }
  }
`;
