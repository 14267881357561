import gql from 'graphql-tag';

export interface ListSSOProfilesQueryResponse {
  data: {
    listSSOProfiles: {
      organization: {
        id: number;
      };
      auth0Id: string;
      domain: string;
      ssoVendor: string;
    }[];
  };
}

export default gql`
  query ListSSOProfiles {
    listSSOProfiles {
      organization {
        id
        name
        imageUrl
      }
      auth0Id
      domain
      ssoVendor
    }
  }
`;
