export const NO_PHONE_NUMBER = 'NoAddressRegistered';
export const NO_SUCH_USER = 'UserNotFound';
export const USER_NOT_FOUND_ERROR = 'UserNotFoundError';
export const USER_NOT_FOUND_ERROR_MESSAGE = 'No user found';
export const INVALID_TOKEN = 'InvalidToken';
export const VALIDATION_TOKEN_EXPIRED = 'TokenExpired';
export const NO_ORG_FOR_EMAIL = 'NoOrganizationForEmailDomain';
export const ADDRESS_IN_USE = 'AddressInUse';
export const ADDRESS_ALREADY_VERIFIED = 'AddressAlreadyVerified';
export const ORG_NAME_ALREADY_EXIST = 'OrganizationAlreadyExistsForGivenName';
export const ORG_DOMAIN_ALREADY_EXIST = 'OrganizationAlreadyExistsForDomain';
export const ERROR_SHELL_ACCOUNT = 'InvalidOperationShellAccount';
export const ADDRESS_INVALID = 'InvalidAddress';
export const TOO_MANY_CHALLENGES = 'TooManyChallenges';
export const MAX_ADDRESS_VERIFY_ATTEMPS = 'MaxAddressVerifyAttempts';
export const CHALLENGE_TIME_EXPIRED = 'ChallengeTimeExpired';
export const CHALLENGE_NOT_FOUND = 'NoChallengeEntry';
export const INVALID_OTP = 'InvalidOTP';
export const DOMAIN_BOUND_ADDRESS = 'DomainBoundAddress';

// marketplace
export const POST_NOT_AVAILABLE = 'MarketplacePostNotAvailable';
export const POST_SHIFT_EXPIRED = 'MarketplaceShiftExpired';
export const SURRENDERING_SHIFT_EXPIRED = 'SurrenderingShiftExpired';
export const DESIRED_SHIFT_EXPIRED = 'DesiredShiftExpired';
export const SWAP_REQUEST_NOT_ACTIVE = 'SwapRequestNotActive';
export const SWAP_REQUEST_ALREADY_ACTIVE = 'SwapRequestAlreadyActive';
export const CONFLICT_WITH_DESIRED_SHIFT = 'ConflictWithDesiredShift';
export const CONFLICT_WITH_SURRENDERING_SHIFT = 'ConflictWithSurrenderingShift';

// invite
export const INVITE_CODE_DRAINED = 'InviteCodeDrained';
export const INVITE_CODE_INVALID = 'InvalidInviteCode';
export const INVITE_CODE_EXPIRED = 'InviteCodeExpired';
export const INVITE_CODE_NOT_FOUND = 'InviteCodeNotFound';
export const INVITE_ALREADY_IN_ORGANIZATION = 'UserAlreadyExistsForScope';
export const INVITE_ALREADY_USED_CODE = 'UserAlreadyUsedInviteCode';

// auth
export const INVALID_TOKEN_UNDERSCORE = 'invalid_token';
export const ACCESS_TOKEN_UNDERSCORE = 'access_token';
export const UNAUTH_REQUEST_UNDERSCORE = 'unauthorized_request';

export const UNAUTHORIZED_REQUEST = 'UnauthorizedRequest';
export const INVALID_ORG_SCOPE = 'InvalidOrganizationScope';
export const SHELL_LOGIN = 'LoginErrorShellAccount';
export const INVALID_CREDENTIALS = 'InvalidCredentials';
export const NO_RECORD = 'RecordNotFoundError';
export const ALREADY_EXISTS = 'UserAlreadyExists';
export const VULNERABLEPASSWORD = 'VulnerablePassword';
export const LOCKED_ACCOUNT = 'LoginErrorLockedAccount';
export const INVALID_ACCESS_TOKEN = 'InvalidAccessToken';
export const INVALID_REFRESH_TOKEN = 'InvalidRefreshToken';

export const EXPIRED_ACCESS_TOKEN_ERROR_MESSAGE = 'Access token has expired!';

// Messaging
export const UserAlreadyRespondedToTemplateMessage = 'UserAlreadyRespondedToTemplateMessage';
export const EMPTY_SEARCH_QUERY = 'EmptySearchQuery';
// unknown
export const UNKNOWN_ERROR_STRING = 'Unknown Error occurred';

//Profile
export const ONE_ADDRESS_REQUIRED = 'OneAddressRequired';

export const INVALID_ADDRESS_UPDATE_REQUEST = 'InvalidAddressUpdateRequest';
export const DUPLICATE_ADDRESSES = 'DuplicateAddresses';
export const UNVERIFIED_ADDRESS = 'UnverifiedAddress';
export const INVALID_ADDRESS_COUNT = 'InvalidAddressCount';

export const GET_OPC_NETWORK_ERROR = (error: string) => {
  return `${error} - Failed to update address preferences.`;
};
export const OPC_DEFAULT_ERROR = 'Failed to update address preferences.';

export const UPDATE_PROFILE_FIELDS_NETWORK_ERROR = 'Error occurred when updating profile form fields';
export const UPDATE_ADDRESS_VISIBILITY_NETWORK_ERROR = 'Error occurred when updating address visibility';
export const DELETE_PROFILE_ADDRESS_ERROR = 'Error occurred when deleting address';
export const UPDATE_PROFILE_ERROR = 'Error occurred when updating profile';
export const UPDATE_ADDRESS_LABEL_NETWORK_ERROR = 'Error occurred while updating address label';
export const NETWORK_ERROR = 'Failed to make the network request, please check your internet connection and try again';
export const CANNOT_MODIFY_DIR_SYNC_FIELDS_ERROR = 'CannotModifyDirSyncManagedFields';
export const CANNOT_MODIFY_DIR_SYNC_FIELDS_ERROR_MESSAGE = 'Cannot modify dir sync managed fields';
export const TOO_MANY_CHALLENGES_MSG = 'Too many challenges, please wait before starting another challenge';
export const VALIDATION_CODE_FAILED_SEND_MSG =
  'Failed to request validation code, please check your internet connection and try again';
export const ADDRESS_INVALID_MSG = 'Invalid phone number';

export const TOO_MANY_FAILED_ATTEMPTS =
  'You’ve failed to verify your email too many times. Enter a different email or try again later.';
export const CHALLENGE_TIME_EXPIRED_MESSAGE = 'Token expired. Please try again.';
export const CHALLENGE_NOT_FOUND_MESSAGE = 'Verification failed. Please try again.';
export const INVALID_OTP_MESSAGE = 'Invalid token. Please try again.';
export const UNKNOWN_ERROR_CODE = 'Unknown error occurred, failed to verify code';
export const FAILED_REQUEST_CODE =
  'Failed to request verification code, please check your internet connection and try again';
export const FAILED_RESEND_CODE =
  'Failed to resend verification code, please check your internet connection and try again';
export const GEOGRAPHICAL_API_ERROR = 'Error occurred when fetching users geographical location';
export const FETCH_ORGANIZATION_SITES_ERROR =
  'An error has occurred while fetching organization data. Please refresh the page to try again. If the problem persists, please contact Hypercare support.';

export const ACCOUNT_REMOVED_INPUT_ERROR =
  'Account has been removed, please contact an administrator of your organization';
export const GENERIC_PASSWORD_INPUT_ERROR = 'Error occurred when fetching password for organization';
