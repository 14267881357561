import ApiHelper from 'src/api';
import {AuthHelper} from 'src/auth';

class SignupPersonalDetailsViewModel {
  repository: any;
  nextButtonPressed;
  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    const domain = values.email.split('@')[1];
    try {
      const getOrgByDomain = await ApiHelper.PrivateEndpoints.getOrganizationByDomain({domain});
      if ('error' in getOrgByDomain) {
        console.error('Error occurred when fetching organization by domain');
        return null;
      }
      if (getOrgByDomain.data.organizationForDomain === null) {
        this.nextButtonPressed(values);
      } else if (getOrgByDomain.data.organizationForDomain.name) {
        const partialAccessTokenResponse = await AuthHelper.exchangeOTPTokenForPartialAccessToken(values.challengeId);
        if (partialAccessTokenResponse.data) {
          const getAccountsForEmail = await ApiHelper.PrivateEndpoints.getAccounts({
            partialAccessToken: partialAccessTokenResponse.data.access_token,
          });
          const match = getAccountsForEmail.data.accounts.accounts.some(
            (account) => account.organization.id === getOrgByDomain.data.organizationForDomain.id,
          );
          if (match) {
            this.nextButtonPressed(values);
          } else {
            const newValues = {
              ...values,
              organizationUrl: getOrgByDomain.data.organizationForDomain.url,
              currentStep: 6,
              orgName: getOrgByDomain.data.organizationForDomain.name,
              orgId: getOrgByDomain.data.organizationForDomain.id,
            };
            this.nextButtonPressed(newValues);
          }
        }
      } else {
        return {error: 'Invalid invite code'};
      }
    } catch (error) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };
}

export default SignupPersonalDetailsViewModel;
