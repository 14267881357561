import gql from 'graphql-tag';
import {SearchUserType} from 'src/types/PaginatedTypes';

export type SearchUsersQueryResponse = {
  searchQuery: {
    searchUsersInScopes: {
      continuationId: string;
      totalResultsCount: number;
      users: SearchUserType[];
    };
  };
};

export const SEARCH_USERS_QUERY = gql`
  query SearchUsersInScopes(
    $text: String!
    $options: SearchUsersOptions
    $continuationId: ID
    $limit: Int
    $direction: PaginationDirection
    $scopes: [OrganizationalUnitInput!]
  ) {
    searchQuery(text: $text) {
      searchUsersInScopes(
        options: $options
        continuationId: $continuationId
        limit: $limit
        direction: $direction
        scopes: $scopes
      ) {
        ...SearchUsersInScopesResultFragment
      }
    }
  }

  fragment SearchUsersInScopesResultFragment on SearchUsersInScopesResult {
    ... on InvalidOrganizationScope {
      __typename
      message
    }
    ...SearchUsersInScopesFragment
  }

  fragment SearchUsersInScopesFragment on SearchUsersInScopes {
    continuationId
    totalResultsCount
    pageSize
    users {
      ...OrganizationMemeberFragment
    }
  }

  fragment OrganizationMemeberFragment on OrganizationMember {
    id
    firstname: firstName
    lastname: lastName
    memberStatus
    username
    role
    profilePic: avatar {
      ...AttachmentFragment
    }
  }

  fragment AttachmentFragment on Attachment {
    id
    url
    mimeType
    fileName
  }
`;
