import React from 'react';
import ApiHelper from '../../../../api';
import {
  CURRENT_SELECTED_ORGANIZATION,
  ORG_URL,
  ORGANIZATION_ACCOUNTS_DATA,
  SSO_VENDOR,
} from '../../../../constants/storageKeys';
import {AuthSSOVendors, RequestVerificationPayload} from '../../../../types';
import {AccountStatus, OrganizationAccountsCacheData, OrgLoginMethods, OrgViews} from '../../../../types/sta';
import {
  ACCOUNT_REMOVED_INPUT_ERROR,
  GENERIC_PASSWORD_INPUT_ERROR,
  TOO_MANY_CHALLENGES,
} from '../../../../constants/networkError';
import AuthHelper from '../../../../auth/authHelper';
import authHelper from '../../../../auth/authHelper';
import {convertAuthResponseToAuthDTO} from '../../../../auth/convertAuthResponseToAuthDTO';
import {checkIfUserHasLoginScope} from '../../../../utils/userHelper/userUtils';
import {CurrentSelectedOrganization} from '../../../../microfrontend/types/login.types';
import {EMAIL} from 'src/constants/storageKeys';
import {localStorageService} from '../../../../services/localStorageService';

export const OrganizationViewModel = () => {
  const fetchOrganizationByURL = async (orgURL: string) => {
    const result = await ApiHelper.PublicEndpoints.fetchOrganizationByURL(orgURL);

    if (result.data?.findOrganizationByURL.__typename === 'OrganizationNotFound') {
      return {data: null, error: 'Organization Not Found'};
    }
    if (result) return {data: result.data, error: false};

    return {data: null, error: 'Network error'};
  };

  const fetchOrganizationByName = async (name: string) => {
    const result = await ApiHelper.PublicEndpoints.fetchOrganizationByName(name);

    if (result) return result;
  };

  const storeUserDetailsInLocalStorage = (email: string, org: CurrentSelectedOrganization) => {
    localStorage.setItem(ORG_URL, org.url);
    localStorage.setItem(CURRENT_SELECTED_ORGANIZATION, JSON.stringify(org));
    localStorage.setItem(EMAIL, email);
    sessionStorage.setItem('email', email);
  };

  const handleSSOLogin = async (ssoProfile: any) => {
    localStorage.setItem(SSO_VENDOR, ssoProfile.ssoVendor);
    localStorage.setItem('flowStep', 'findMyOrg');

    if (ssoProfile.ssoVendor === AuthSSOVendors.WORKOS) {
      await getSSOAuthorizationUrl(ssoProfile.auth0Id, ssoProfile.ssoVendor);
    } else {
      return {auth0Id: ssoProfile.auth0Id, address: ''};
    }
  };

  const handleOTPLogin = async (email: string) => {
    const startRequestOTPFlow = await requestAddressOTP({address: email, type: 'email'});

    if (typeof startRequestOTPFlow === 'string') {
      return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow};
    } else {
      return startRequestOTPFlow;
    }
  };

  const handlePasswordOrOTPLogin = async (
    basicUser: any,
    checkOTP: boolean,
    checkPassword: boolean,
    email: string,
    orgUrl: string,
    orgName: string,
  ) => {
    if (basicUser.memberStatus === AccountStatus.INACTIVE) {
      const challengeIdres = await requestAddressOTP({address: email, type: 'email'});
      console.log(challengeIdres);
      if (typeof challengeIdres === 'string') {
        const challengeId: string = challengeIdres;
        console.log(challengeId);
        window.routerHistory.push(
          `/signup?email=${email?.replace(
            '+',
            '%2b',
          )}&challengeId=${challengeId}&orgUrl=${orgUrl}&step=2&activateShellAccountFlow=true&orgName=${orgName}`,
        );
      } else {
        if (challengeIdres.error) {
          return {
            error: 'Too many challenges, please wait before starting another challenge',
          };
        } else {
          return {
            error: 'Failed to request validation code, please check your internet connection and try again',
          };
        }
      }
    } else {
      if (checkOTP && checkPassword) {
        return {screen: OrgLoginMethods.PASSWORD, isMethodSwitchable: true};
      }
      if (checkPassword) {
        return {screen: OrgLoginMethods.PASSWORD};
      }
      if (checkOTP) {
        return await handleOTPLogin(email);
      }
    }

    return {error: 'Please use corp email to sign in'};
  };

  const handleInactiveOrNoMatchAccount = async (emailDomain: string) => {
    const getOrgByDomain = await ApiHelper.PrivateEndpoints.getOrganizationByDomain({
      domain: emailDomain,
    });

    if ('error' in getOrgByDomain) {
      return {error: 'No matched account'};
    }

    if (getOrgByDomain.data.organizationForDomain) {
      // sign-up logic here
    }
  };
  const submitEmailAddress = async (email: string, currentSelectedOrg: CurrentSelectedOrganization) => {
    const currentSavedAccountData =
      localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    const isAccountLoggedIn = currentSavedAccountData?.savedOrganizations.find(
      (org) => org.user.email === email && org.accessToken,
    );
    if (isAccountLoggedIn?.accessToken) {
      return {
        screen: 'login',
        data: isAccountLoggedIn,
      };
    }

    storeUserDetailsInLocalStorage(email, currentSelectedOrg);
    const basicUser = await getBasicUser(email, currentSelectedOrg.url);
    const emailDomain = email.split('@')[1];

    if (!basicUser || basicUser === 'UserNotFound') {
      return {error: 'No account found for this organization'};
    }

    const checkSSOScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.SSO);
    const checkOTPScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.OTP);
    const checkPasswordScope = checkIfUserHasLoginScope(basicUser, OrgLoginMethods.PASSWORD);

    const firstSSOProfile = basicUser.ssoProfiles?.[0];

    if (basicUser.memberStatus === AccountStatus.BLOCKED) {
      return {error: 'Account has been removed'};
    }

    const listSSOProfiles = await ApiHelper.PublicEndpoints.listSSOProfiles();

    let checkIfSSOEmailOfSelectedOrg;

    if ('data' in listSSOProfiles) {
      checkIfSSOEmailOfSelectedOrg = listSSOProfiles?.data?.listSSOProfiles.find(
        (profile) => profile.organization.id === currentSelectedOrg.id,
      );
    }

    if (checkIfSSOEmailOfSelectedOrg) {
      if (basicUser.memberStatus === AccountStatus.ACTIVE || basicUser.memberStatus === AccountStatus.INACTIVE) {
        if (basicUser.loginScopes.includes(OrgLoginMethods.SSO)) {
          return await handleSSOLogin(firstSSOProfile);
        } else {
          if (
            basicUser.loginScopes.includes(OrgLoginMethods.OTP) ||
            basicUser.loginScopes.includes(OrgLoginMethods.PASSWORD)
          ) {
            return await handlePasswordOrOTPLogin(
              basicUser,
              checkOTPScope,
              checkPasswordScope,
              email,
              currentSelectedOrg.url,
              currentSelectedOrg.name,
            );
          } else {
            return {
              error: 'No supported login methods',
            };
          }
        }
      } else {
        return await handleSSOLogin(firstSSOProfile);
      }
    }

    if (basicUser.memberStatus === AccountStatus.ACTIVE || basicUser.memberStatus === AccountStatus.INACTIVE) {
      if (!checkOTPScope && !checkPasswordScope) {
        return {error: 'No supported login methods'};
      }

      return await handlePasswordOrOTPLogin(
        basicUser,
        checkOTPScope,
        checkPasswordScope,
        email,
        currentSelectedOrg.url,
        currentSelectedOrg.name,
      );
    }

    if (firstSSOProfile?.domain === emailDomain) {
      const challengeIdres = await requestAddressOTP({address: email, type: 'email'});
      console.log(challengeIdres);
      if (typeof challengeIdres === 'string') {
        const challengeId: string = challengeIdres;
        console.log(challengeId);
        window.routerHistory.push(
          `/signup?email=${email?.replace('+', '%2b')}&challengeId=${challengeId}&orgUrl=${
            currentSelectedOrg.url
          }&step=2&orgName=${currentSelectedOrg.name}`,
        );
      }
    } else {
      return {error: 'No matched accounts'};
    }
  };

  const getBasicUser = async (email: string, orgUrl: string) => {
    const res = await ApiHelper.PublicEndpoints.fetchBasicUser(email, orgUrl);

    if (res?.data?.user.__typename === 'UserNotFound') {
      return 'UserNotFound';
    }

    if (res.data) {
      return res.data.user;
    }
  };

  const submitPassword = async (orgUrl: string, email: string, password: string) => {
    const res = await authHelper.fetchAuthInfoWithEmailAndPassword(orgUrl, email, password);
    if (!res || res.data.error) {
      if (res.data.reason === 'account_locked') {
        return {error: ACCOUNT_REMOVED_INPUT_ERROR};
      } else {
        return {
          error: GENERIC_PASSWORD_INPUT_ERROR,
        };
      }
    }

    if (res && res.data) {
      const convertResponseToAuth = convertAuthResponseToAuthDTO(res.data);

      return {
        data: convertResponseToAuth,
      };
    }
  };

  const submitOTP = async (challengeId: string, otp: string, orgUrl: string) => {
    const res = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(challengeId, otp);

    if (!res || res?.data?.errors) {
      return {
        error: 'Error occurred when fetching OTP',
      };
    }

    if (res && res.data?.response.status) {
      const otpGrantTypeResponse = await exchangeOTPTokenForPartialToken(challengeId);

      if (otpGrantTypeResponse.data?.access_token) {
        const otpExchangeToken = await AuthHelper.exchangeToken(orgUrl, otpGrantTypeResponse.data?.access_token);

        if (otpExchangeToken?.data?.error) {
          return {
            error: otpExchangeToken.data.error_description,
          };
        }
        if (otpExchangeToken.data) {
          const convertResponseToAuth = convertAuthResponseToAuthDTO(otpExchangeToken.data);

          return {
            data: convertResponseToAuth,
          };
        }
      }
      if (otpGrantTypeResponse.error) {
        return {success: false, error: otpGrantTypeResponse.error};
      }
    }

    return {
      success: false,
      error: 'Error occurred',
    };
  };

  const getAccessTokenforOTP = async (challengeId: string, orgUrl: string) => {
    const otpGrantTypeResponse = await exchangeOTPTokenForPartialToken(challengeId);

    if (otpGrantTypeResponse.data?.access_token) {
      const otpExchangeToken = await AuthHelper.exchangeToken(orgUrl, otpGrantTypeResponse.data?.access_token);

      if (otpExchangeToken?.data?.error) {
        return {
          error: otpExchangeToken.data.error_description,
        };
      }
      if (otpExchangeToken.data) {
        const convertResponseToAuth = convertAuthResponseToAuthDTO(otpExchangeToken.data);

        return {
          data: convertResponseToAuth,
        };
      }
    }
    if (otpGrantTypeResponse.error) {
      return {success: false, error: otpGrantTypeResponse.error};
    }
  };

  const exchangeOTPTokenForPartialToken = async (challengeId: string) => {
    const res = await AuthHelper.exchangeOTPTokenForPartialAccessToken(challengeId);
    return res;
  };

  const requestAddressOTP = async ({address, type}: RequestVerificationPayload) => {
    const res = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA({address, type});

    if (res && res?.data?.response) {
      const challengeId: string = res.data.response.challengeId;
      return challengeId;
    } else {
      if (res && res?.data?.errors[0].name === TOO_MANY_CHALLENGES) {
        return {
          error: 'Too many challenges, please wait before starting another challenge',
        };
      } else {
        return {
          error: 'Failed to request validation code, please check your internet connection and try again',
        };
      }
    }
  };

  const getSSOAuthorizationUrl = async (connectionId: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(connectionId, provider);
    return result;
  };

  return {
    fetchOrganizationByURL,
    submitEmailAddress,
    fetchOrganizationByName,
    submitPassword,
    submitOTP,
    getAccessTokenforOTP,
    handleOTPLogin,
  };
};
