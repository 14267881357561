import ApiHelper from 'src/api';
import {AuthHelper} from 'src/auth';

import {
  CreateUserPayload,
  RequestVerificationPayload,
  SSOProfileForUserRequest,
  SSOProfileType,
  CreateOrganizationPayload,
  VerifyAddressValidationPayload,
} from 'src/types';
import {
  ActivateShellAccountMutationInputPayload,
  ActivateShellAccountMutationResponse,
} from '../../gql/v2/mutation/ActivateShellAccountMutation';

interface resultObject {
  success: boolean;
  data?: any | undefined;
  error?: any | undefined;
}

type addressType = 'email' | 'phoneNumber';

export interface HypercareSignupRepositoryProps {
  requestAddressToken: (address: string, type: addressType) => Promise<resultObject>;
  verifyAddressToken: (challengeId: string, token: string) => Promise<resultObject>;
  requestAddressTokenSTA: (address: string, type: addressType) => Promise<resultObject>;
  verifyAddressTokenSTA: (challengeId: string, token: string) => Promise<resultObject>;
  createUserAccount: (payload: CreateUserPayload) => Promise<resultObject>;
  createSTAUserAccount: (payload: CreateUserPayload) => Promise<resultObject>;
  createSTAOrg: (payload: CreateOrganizationPayload) => Promise<resultObject>;
  fetchAuthInfo: (username: string, password: string) => Promise<resultObject>;
  acceptEula: (accessToken: string) => Promise<resultObject>;
  checkIfAddressIsSSO: (email: string) => Promise<{
    success: boolean;
    data?: SSOProfileForUserRequest;
    error?: any;
  }>;
  activateShellAccount: ({
    challengeId,
    organizationUrl,
    userDetails,
  }: ActivateShellAccountMutationInputPayload) => Promise<ActivateShellAccountMutationResponse>;
}

class HypercareSignupRepository {
  SSOConfiguration: Promise<SSOProfileType[]> | null;

  requestAddressToken = async (address: string, type: addressType) => {
    const requestVerificationPayload: RequestVerificationPayload = {
      address,
      type,
    };

    const requestVerificationResult = await ApiHelper.PrivateEndpoints.addressVerificationRequest(
      requestVerificationPayload,
    );

    return requestVerificationResult;
  };

  verifyAddressToken = async (challengeId: string, token: string) => {
    const verificationResult = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(challengeId, token);
    return verificationResult;
  };

  requestAddressTokenSTA = async (address: string, type: addressType) => {
    const requestVerificationPayload: RequestVerificationPayload = {
      address,
      type,
    };

    const requestVerificationResult = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA(
      requestVerificationPayload,
    );

    return requestVerificationResult;
  };

  verifyAddressTokenSTA = async (challengeId: string, token: string) => {
    const verificationResult = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(challengeId, token);
    return verificationResult;
  };

  checkIfAddressIsSSO = async (email: string) => {
    try {
      const result = await ApiHelper.PublicEndpoints.getSSODomainsForUsername(email);
      return result;
    } catch (err) {
      return {success: false, data: undefined, error: err};
    }
  };

  createUserAccount = async (payload: CreateUserPayload) => {
    const createUserAccountResult = await ApiHelper.PublicEndpoints.createUser(payload);
    return createUserAccountResult;
  };

  createSTAUserAccount = async (payload: CreateUserPayload) => {
    const createUserAccountResult = await ApiHelper.PublicEndpoints.createUserSTA(payload);
    return createUserAccountResult;
  };

  createSTAOrg = async (payload: CreateOrganizationPayload) => {
    const createUserAccountResult = await ApiHelper.PublicEndpoints.createOrganization(payload);
    return createUserAccountResult;
  };

  fetchAuthInfo = async (username: string, password: string) => {
    const authResult: resultObject = await AuthHelper.fetchAuthInfo(username, password);

    return authResult;
  };

  acceptEula = async (accessToken: string) => {
    const acceptEulaResult = await ApiHelper.PrivateEndpoints.acceptEula(accessToken);

    return acceptEulaResult;
  };

  activateShellAccount = async ({
    challengeId,
    organizationUrl,
    userDetails,
  }: ActivateShellAccountMutationInputPayload) => {
    const activateShellAccResult = await ApiHelper.PublicEndpoints.activateShellAccountMutation(
      challengeId,
      organizationUrl,
      userDetails,
    );
    return activateShellAccResult;
  };
}

export default HypercareSignupRepository;
