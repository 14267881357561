import React from 'react';

export const AddButtonV2 = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1512_325)">
      <path
        d="M13 11V5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V11H5C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13H11V19C11 19.5523 11.4477 20 12 20C12.5523 20 13 19.5523 13 19V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H13Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_1512_325">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
