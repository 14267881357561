import {AuthPayload} from 'src/types';
import {HypercareSignupRepositoryProps} from '../HypercareSignupRepository';
import sleep from 'src/utils/sleep';
import {ALREADY_EXISTS, INVITE_CODE_NOT_FOUND, VULNERABLEPASSWORD} from 'src/constants/networkError';
import {AuthHelper, AuthProvider} from 'src/auth';
import ApiHelper from 'src/api';
import {ActivateShellAccountMutationInputPayload} from 'src/gql/v2/mutation/ActivateShellAccountMutation';

class CreateAccountViewModel {
  nextButtonPressed;
  repository: HypercareSignupRepositoryProps;

  constructor(nextButtonPressed) {
    this.nextButtonPressed = nextButtonPressed;
  }

  handleNextButtonPressed = async (values) => {
    try {
      if (values.activateShellAccountFlow === 'true') {
        const activateResult = await this.handleActivateShellAccount(
          values.challengeId,
          values.organizationUrl,
          values.userDetails,
        );
        if (activateResult?.success) {
          const authResult = await AuthHelper.fetchAuthInfoWithEmailAndPassword(
            values.organizationUrl,
            values.email,
            values.userDetails.password,
          );
          if (authResult) {
            const authPayload: AuthPayload = {...(authResult.data as AuthPayload)};
            const result = await this.handleAcceptEula(authResult.data.access_token as string);

            // TODO: fix with proper handling - temp solution as backend input delay
            // e.g. might not write the status before login call
            await sleep(2000);

            if (result) this.nextButtonPressed(values);
          } else {
            return {error: 'accessToken'};
          }
        } else {
          return {error: activateResult.message};
        }
      } else if (values.orgName && values.organizationUrl) {
        const {inviteCode, ...rest} = values;
        const newValues = inviteCode ? {...rest, inviteCode} : rest;
        const createUserAccountResult = await this.handleCreateUserAccount(newValues);
        if (createUserAccountResult?.data.data.createAccount.firstName) {
          const authResult = await AuthHelper.fetchAuthInfoWithEmailAndPassword(
            values.orgUrl,
            values.email,
            values.userDetails.password,
          );
          if (authResult) {
            const authPayload: AuthPayload = {...(authResult.data as AuthPayload)};
            const result = await this.handleAcceptEula(authResult.data.access_token as string);

            // TODO: fix with proper handling - temp solution as backend input delay
            // e.g. might not write the status before login call
            await sleep(2000);

            if (result) this.nextButtonPressed(values);
          } else {
            return {error: 'accessToken'};
          }
        } else {
          return {error: createUserAccountResult.data.data.createAccount.message};
        }
      } else if (values.inviteCode) {
        const fetchOrgByName = await ApiHelper.PublicEndpoints.fetchOrganizationByName(values.orgName);
        const organizationUrl = fetchOrgByName.data?.findOrganizationByName.organizations[0].url;
        const orgId = fetchOrgByName.data?.findOrganizationByName.organizations[0].id;
        const loginMethods = fetchOrgByName.data?.findOrganizationByName.organizations[0].loginMethods;
        if (organizationUrl) {
          const newValues = {...values, organizationUrl: organizationUrl, orgId: orgId, loginMethods: loginMethods};
          const createUserAccountResult = await this.handleCreateUserAccount(newValues);
          if (createUserAccountResult?.data.data.createAccount.firstName) {
            const authResult = await AuthHelper.fetchAuthInfoWithEmailAndPassword(
              organizationUrl,
              values.email,
              values.userDetails.password,
            );
            if (authResult) {
              const authPayload: AuthPayload = {...(authResult.data as AuthPayload)};
              const result = await this.handleAcceptEula(authResult.data.access_token as string);

              // TODO: fix with proper handling - temp solution as backend input delay
              // e.g. might not write the status before login call
              await sleep(2000);

              if (result) this.nextButtonPressed(newValues);
            } else {
              return {error: 'accessToken'};
            }
          } else {
            return {error: createUserAccountResult.data.data.createAccount.message};
          }
        }
      } else if (values.orgName) {
        const createOrgResult = await this.handleCreateOrg({
          organizationDetails: {
            name: values.orgName,
          },
        });
        const organizationUrl = createOrgResult.data.data.createOrganization.url;
        const orgId = createOrgResult.data.data.createOrganization.id;
        const inviteCode = createOrgResult.data.data.createOrganization.inviteCodes[0].code;
        const newValues = {...values, organizationUrl: organizationUrl, inviteCode: inviteCode, orgId: orgId};
        if (createOrgResult) {
          const createUserAccountResult = await this.handleCreateUserAccount(newValues);
          if (createUserAccountResult?.data.data.createAccount.firstName) {
            const authResult = await AuthHelper.fetchAuthInfoWithEmailAndPassword(
              organizationUrl,
              values.email,
              values.userDetails.password,
            );
            if (authResult) {
              const authPayload: AuthPayload = {...(authResult.data as AuthPayload)};
              const result = await this.handleAcceptEula(authResult.data.access_token as string);

              // TODO: fix with proper handling - temp solution as backend input delay
              // e.g. might not write the status before login call
              await sleep(2000);

              if (result) this.nextButtonPressed(newValues);
            } else {
              return {error: 'accessToken'};
            }
          } else {
            return {error: createUserAccountResult.data.data.createAccount.message};
          }
        }
      }
    } catch (error) {
      let errorMsg = 'Unknown error occurred, please check your internet connection and try again';
      if (error.errors) {
        errorMsg = error.errors[0].message;
      }
      return errorMsg;
    }
  };

  handleCreateUserAccount = async (payload) => {
    const createUserResult = await this.repository?.createSTAUserAccount(payload);
    return createUserResult;
  };

  handleCreateOrg = async (payload) => {
    const createOrgResult = await this.repository?.createSTAOrg(payload);
    return createOrgResult;
  };

  handleAcceptEula = async (accessToken) => {
    const fetchEulaPayload = await this.repository?.acceptEula(accessToken);
    return fetchEulaPayload;
  };

  handleActivateShellAccount = async (challengeId, organizationUrl, userDetails) => {
    const activeShellAccountResult = await this.repository?.activateShellAccount({
      challengeId,
      organizationUrl,
      userDetails,
    });

    if (activeShellAccountResult.data.activateAccount.id) {
      return {success: true};
    } else {
      return {success: false, data: activeShellAccountResult.data.activateAccount};
    }
  };
}

export default CreateAccountViewModel;
