import gql from 'graphql-tag';

export interface MemberIdentifier {
  email: string;
  url: string;
}

export interface FetchSSOProfileForUserResponse {
  data: {
    ssoProfileForUser: {
      __typename: string;
      profile: {
        auth0Id: string;
        ssoId: string;
        ssoVendor: string;
        domain: string;
      };
    };
  };
}

export const FETCH_SSO_PROFILE_FOR_USER = gql`
  query SSOProfileForUser($identifier: MemberIdentifier!) {
    ssoProfileForUser(identifier: $identifier) {
      __typename
      ... on SSOProfileResult {
        profile {
          auth0Id
          ssoId
          ssoVendor
          domain
        }
      }
      ... on Error {
        message
      }
    }
  }
`;
