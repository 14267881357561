import gql from 'graphql-tag';

export interface DomainOrganization {
  __typename: string;
  name: string;
  imageUrl: string;
  url: string;
  isOpenDoor: boolean;
  id: number;
  termsOfServiceURL: string;
  privacyPolicyURL: string;
}

export interface FetchOrganizationByDomainResponse {
  data: {
    organizationForDomain: DomainOrganization;
  };
}

export const FETCH_ORGANIZATION_BY_DOMAIN_QUERY = gql`
  query GetOrganizationByDomain($domain: String!) {
    organizationForDomain(domain: $domain) {
      ...OrganizationFragment
    }
  }

  fragment OrganizationFragment on Organization {
    __typename
    name
    imageUrl
    url
    isOpenDoor
    id
    termsOfServiceURL
    privacyPolicyURL
  }
`;
