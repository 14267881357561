import ApiHelper from '../../../../api';
import {
  ACCOUNT_DISCOVERY_FLOW_STEP,
  INTRO_DONE,
  ORGANIZATION_ACCOUNTS_DATA,
  SSO_VENDOR,
} from '../../../../constants/storageKeys';
import {AuthSSOVendors, RequestVerificationPayload} from '../../../../types';
import {TOO_MANY_CHALLENGES} from '../../../../constants/networkError';
import {
  Account,
  AccountStatus,
  OrganizationAccountsCacheData,
  OrganizationAccountsCacheDataFromMobile,
  OrgLoginMethods,
  WebViewCallBacks,
} from '../../../../types/sta';
import AuthHelper from '../../../../auth/authHelper';
import {convertAuthResponseToAuthDTO} from '../../../../auth/convertAuthResponseToAuthDTO';
import {localStorageService} from '../../../../services/localStorageService';
import {callNative} from '../../../../nativeBridge';
import {toast} from 'react-toastify';
import {DomainOrganization} from '../../../../gql/v2/query/FetchOrganizationByDomain';

export const AccountDiscoveryViewModel = () => {
  const createSavedOrganizationData = (account) => {
    return {
      accessToken: account.accessToken || null,
      accessTokenExpiresAt: account.accessTokenExpiresAt || null,
      refreshToken: account.refreshToken || null,
      organization: account.organization,
      refreshTokenExpiresAt: null,
      scopeToken: account.scopeToken || '',
      sessionID: '',
      user: {
        id: account.user.id,
        firstname: account.user.firstname,
        lastname: account.user.lastname,
        username: account.user.username || account.user.email,
        email: account.user.email,
      },
    };
  };

  const handleSaveAccountsQueryParamToCache = (encodedMobileData: OrganizationAccountsCacheDataFromMobile) => {
    try {
      let cacheData = getCacheData();

      if (encodedMobileData) {
        for (let acc of encodedMobileData.savedOrganizations) {
          const newOrganizationData = createSavedOrganizationData(acc);
          if (!cacheData.savedOrganizations.find((org) => org.user.id === newOrganizationData.user.id))
            cacheData.savedOrganizations.unshift(newOrganizationData);
        }

        updateCacheData(cacheData);
        return cacheData;
      }
    } catch (e) {
      return {} as OrganizationAccountsCacheData;
    }
  };

  const decodeSavedAccountString = (encodedSavedAccString: string) => {
    if (!encodedSavedAccString) {
      return '';
    }
    try {
      const encodedData = window.atob(encodedSavedAccString);
      return JSON.parse(encodedData);
    } catch (e) {
      console.error('Error decoding saved account string:', e);
      return '';
    }
  };

  const checkIfAddressIsSSO = async (email: string) => {
    const emailDomain = extractDomainFromEmail(email);

    const orgByDomain = await fetchOrganizationByDomain(emailDomain);
    if (!orgByDomain) return null;

    localStorage.setItem('orgUrl', orgByDomain.url);

    const ssoProfile = await fetchSSOProfileForUser(email, orgByDomain.url);
    if (!ssoProfile) return {error: 'Error occurred when fetching sso profile for user'};

    if (!ssoProfile.profile && orgByDomain.isOpenDoor) {
      return;
    }

    if (ssoProfile.__typename === 'UserNotFound') {
      return {error: 'User not found'};
    }
    return extractSSOProfileData(ssoProfile.profile);
  };

  const extractDomainFromEmail = (email: string) => {
    return email.split('@')[1];
  };

  const fetchOrganizationByDomain = async (domain: string) => {
    try {
      const response = await ApiHelper.PrivateEndpoints.getOrganizationByDomain({domain});
      if ('error' in response) {
        console.error('Error occurred when fetching organization by domain');
        return null;
      }
      return response.data?.organizationForDomain || null;
    } catch (error) {
      console.error('Exception occurred while fetching organization by domain:', error);
      return null;
    }
  };

  const fetchSSOProfileForUser = async (email: string, url: string) => {
    try {
      const response = await ApiHelper.PublicEndpoints.getSSOProfileForUser({email, url});
      if ('error' in response) {
        console.error('Error occurred when fetching SSO profile for user');
        return null;
      }
      return response.ssoProfileForUser;
    } catch (error) {
      console.error('Exception occurred while fetching SSO profile:', error);
      return null;
    }
  };

  const extractSSOProfileData = (profile: any) => {
    if (!profile) return {error: 'No sso profile information found'};
    const {auth0Id, ssoId, ssoVendor, domain} = profile;
    return {auth0Id, ssoId, ssoVendor, domain};
  };

  const getSSOAuthorizationUrl = async (connectionId: string, provider: AuthSSOVendors) => {
    const result = await ApiHelper.PrivateEndpoints.getSSOAuthorizationUrl(connectionId, provider);
    return result;
  };

  const enterEmail = async (email: string) => {
    const emailSSOResult = await checkIfAddressIsSSO(email);
    const getEnteredEmailDomain = email.split('@')[1];

    if (emailSSOResult && 'error' in emailSSOResult) {
      return {error: 'Error occurred when fetching sso profile information'};
    }

    if (
      emailSSOResult &&
      emailSSOResult?.domain === getEnteredEmailDomain &&
      emailSSOResult.ssoVendor &&
      emailSSOResult?.auth0Id
    ) {
      localStorage.setItem(SSO_VENDOR, emailSSOResult?.ssoVendor);
      localStorage.setItem('flowStep', ACCOUNT_DISCOVERY_FLOW_STEP);
      localStorage.setItem('email', email);

      if (emailSSOResult?.ssoVendor === AuthSSOVendors.WORKOS) {
        await getSSOAuthorizationUrl(emailSSOResult?.auth0Id, emailSSOResult?.ssoVendor);
      } else {
        return {auth0Id: emailSSOResult?.auth0Id, address: ''};
      }
    } else {
      const startRequestOTPFlow = await requestAddressOTP({address: email, type: 'email'});

      if (typeof startRequestOTPFlow !== 'string' && startRequestOTPFlow?.error) {
        return startRequestOTPFlow;
      }

      if (typeof startRequestOTPFlow === 'string') {
        return {screen: OrgLoginMethods.OTP, challengeId: startRequestOTPFlow};
      }
    }
  };

  const handleLoginToExistingAccountsFromSignup = async (
    emailToken: string,
    email: string,
    accounts: Account[],
    challengeId: string,
  ) => {
    const emailDomain = extractDomainFromEmail(email);
    const orgByDomain = await fetchOrganizationByDomain(emailDomain);

    if (orgByDomain?.__typename === 'Organization') {
      const checkIfOrgDomainOrgExists = accounts.some((acc) => acc.organization.id === orgByDomain.id);
      if (!checkIfOrgDomainOrgExists && orgByDomain?.isOpenDoor) {
        // we found an open door org
      }
    }

    return handleDomainMatch(OrgLoginMethods.OTP, accounts, email, emailToken, challengeId);
  };

  const handleAccountDiscoveryAfterSSO = async (code: string, getSSOVendor: AuthSSOVendors, email: string) => {
    const orgUrl = localStorage.getItem('orgUrl') || '';
    const otpGrantTypeResponse = await AuthHelper.exchangeSSOCodeForPartialAccessToken(code, getSSOVendor);

    if (!otpGrantTypeResponse?.data?.access_token) return null;

    const accountsResponse = await fetchAccounts(otpGrantTypeResponse.data.access_token);
    if (!accountsResponse) return {error: 'Error occurred when fetching accounts for email'};

    const hasActiveOrShellAccount = checkForActiveOrShellAccount(accountsResponse.accounts);
    if (!hasActiveOrShellAccount) return handleNoActiveOrShellAccount(accountsResponse.accounts);

    const orgByDomain = await fetchOrganizationByDomain(extractDomainFromEmail(email));
    if (!orgByDomain) return null;

    if (orgByDomain && orgByDomain.__typename) {
      return await handleDomainMatch(
        OrgLoginMethods.SSO,
        accountsResponse.accounts,
        email,
        otpGrantTypeResponse.data.access_token,
        '',
        orgByDomain,
      );
    }
  };

  const fetchAccounts = async (accessToken: string) => {
    try {
      const response = await ApiHelper.PrivateEndpoints.getAccounts({partialAccessToken: accessToken});
      if ('error' in response) return null;
      return response.data?.accounts || null;
    } catch (error) {
      console.error('Exception occurred while fetching accounts:', error);
      return null;
    }
  };

  const checkForActiveOrShellAccount = (accounts: Account[]) => {
    return accounts.some(
      (account) => account.memberStatus === AccountStatus.ACTIVE || account.memberStatus === AccountStatus.INACTIVE,
    );
  };

  const checkForDomainMatchAccount = (accounts: Account[], orgUrl: string) => {
    return accounts.some((account) => account.organization.url === orgUrl);
  };

  const handleNoActiveOrShellAccount = (accounts: Account[]) => {
    const hasInactiveAccount = accounts.some((account) => account.memberStatus === AccountStatus.INACTIVE);
    if (hasInactiveAccount) {
      window.routerHistory.push('/');
      toast.error('Account has been removed from org');
    } else {
      console.log('Create account');
    }
  };

  const accountDiscoverySingleAccountOTPFlow = async (
    email: string,
    account: Account,
    accessToken: string,
    challengeId?: string,
  ) => {
    if (account.memberStatus === AccountStatus.INACTIVE) {
      console.log('push to signup');
      window.routerHistory.push(
        `/signup?challengeId=${challengeId}&email=${email?.replace('+', '%2b')}&orgId=${
          account.organization.id
        }&orgUrl=${account.organization.url}&step=3&activateShellAccountFlow=true`,
      );
      return false;
    }

    if (account.loginMethods.includes(OrgLoginMethods.OTP)) {
      await attemptLogin(account, accessToken, true);
    } else if (account.loginMethods.includes(OrgLoginMethods.PASSWORD)) {
      return {screen: OrgLoginMethods.PASSWORD};
    } else {
      saveNotLoggedInAccountToCache(account);
    }
  };

  const handleDomainMatch = async (
    flow: OrgLoginMethods,
    accounts: Account[],
    email: string,
    accessToken: string,
    challengeId?: string,
    domainOrganization?: DomainOrganization,
  ) => {
    console.log(accounts, 'acc');
    if (accounts.length === 1) {
      const account = accounts[0];

      switch (flow) {
        case OrgLoginMethods.OTP:
          await accountDiscoverySingleAccountOTPFlow(email, account, accessToken, challengeId);
          break;

        case OrgLoginMethods.SSO:
          const checkAuthenticatedEmailDomainMatchesSSODomainOfOrg = domainOrganization?.id === account.organization.id;

          if (
            checkAuthenticatedEmailDomainMatchesSSODomainOfOrg &&
            (account.loginMethods.includes(OrgLoginMethods.SSO) || account.loginMethods.includes(OrgLoginMethods.OTP))
          ) {
            await attemptLogin(account, accessToken, true);
          } else {
            await accountDiscoverySingleAccountOTPFlow(email, account, accessToken, challengeId);
          }
          break;
      }
    }
    if (accounts.length >= 1) {
      const result = await loginToLinkedAccounts(flow, accounts, email, accessToken);
      console.log('result', result);
      if (!result || 'error' in result) {
        toast.error('Issue occurred while discovering accounts');
        window.routerHistory.push('/login');
      }
    }

    const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    console.log(currentCacheData, 'currentCahce');

    callNative(WebViewCallBacks.ACCOUNTS_FETCHED, currentCacheData);
  };

  const requestAddressOTP = async ({address, type}: RequestVerificationPayload) => {
    const res = await ApiHelper.PrivateEndpoints.addressVerificationRequestSTA({address, type});

    if (res && res?.data?.response) {
      const challengeId: string = res.data.response.challengeId;
      return challengeId;
    } else {
      if (res && res?.data?.errors[0].name === TOO_MANY_CHALLENGES) {
        return {
          error: 'Too many challenges, please wait before starting another challenge',
        };
      } else {
        return {
          error: 'Failed to request validation code, please check your internet connection and try again',
        };
      }
    }
  };

  const submitOTP = async ({challengeId, otp, email}: {challengeId: string; otp: string; email: string}) => {
    const res = await ApiHelper.PrivateEndpoints.validateAddressVerificationSTA(challengeId, otp);

    console.log(challengeId, otp);

    if (!res || res?.data?.errors || res?.errors) {
      return {
        error: 'Error occurred when fetching OTP',
      };
    }

    if (res && res.data?.response.status) {
      const partialAccessTokenResponse = await AuthHelper.exchangeOTPTokenForPartialAccessToken(challengeId);
      // now we have the partial access token
      if (partialAccessTokenResponse.error) {
        return {error: partialAccessTokenResponse.error};
      }

      if (partialAccessTokenResponse.data) {
        const getAccountsForEmail = await ApiHelper.PrivateEndpoints.getAccounts({
          partialAccessToken: partialAccessTokenResponse.data.access_token,
        });

        if ('error' in getAccountsForEmail) {
          return {error: 'Error occurred when getting accounts for email'};
        }

        if (getAccountsForEmail?.data?.accounts) {
          const checkForAnyActiveOrShellAccount = getAccountsForEmail?.data?.accounts.accounts.some(
            (account) =>
              account.memberStatus === AccountStatus.ACTIVE || account.memberStatus === AccountStatus.INACTIVE,
          );
          if (checkForAnyActiveOrShellAccount) {
            let filterAccounts = getAccountsForEmail?.data?.accounts.accounts.filter(
              (acc) => acc.memberStatus === AccountStatus.ACTIVE || acc.memberStatus === AccountStatus.INACTIVE,
            );
            window.localStorage.setItem('associatedAccounts', JSON.stringify(filterAccounts));
            window.localStorage.setItem('emailToken', partialAccessTokenResponse.data.access_token);
            window.routerHistory.push(`/signup?email=${email?.replace('+', '%2b')}&challengeId=${challengeId}&step=3`);
          } else {
            window.localStorage.removeItem('associatedAccounts');
            window.routerHistory.push(`/signup?email=${email?.replace('+', '%2b')}&challengeId=${challengeId}&step=3`);
          }
        }
        if (getAccountsForEmail?.error) {
          return {error: 'Error occurred when getting accounts for email'};
        }
      }
    }
  };

  const loginToLinkedAccounts = async (
    flow: OrgLoginMethods,
    accountsArray: Account[],
    email: string,
    emailToken: string,
  ) => {
    const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
    const emailDomain = extractDomainFromEmail(email);

    const orgByDomain = await fetchOrganizationByDomain(emailDomain);

    for (const account of accountsArray) {
      if (isAccountAlreadyLoggedIn(currentCacheData, account)) {
        continue;
      }

      account.email = email;

      switch (flow) {
        case OrgLoginMethods.OTP:
          if (isOTPLogin(account)) {
            if (account.memberStatus === AccountStatus.INACTIVE) {
              saveNotLoggedInAccountToCache(account);
            } else {
              const loginResult = await attemptLogin(account, emailToken, false, OrgLoginMethods.OTP);
              if (loginResult?.error) saveNotLoggedInAccountToCache(account);
            }
          } else {
            saveNotLoggedInAccountToCache(account);
          }
          break;
        case OrgLoginMethods.SSO:
          if (orgByDomain && isSSOLogin(account, orgByDomain?.url)) {
            const loginResult = await attemptLogin(account, emailToken);
            if (loginResult?.error) saveNotLoggedInAccountToCache(account);
          } else {
            if (isOTPLogin(account)) {
              if (account.memberStatus === AccountStatus.INACTIVE) {
                saveNotLoggedInAccountToCache(account);
              } else {
                const loginResult = await attemptLogin(account, emailToken, false, OrgLoginMethods.OTP);
                if (loginResult?.error) saveNotLoggedInAccountToCache(account);
              }
            } else {
              saveNotLoggedInAccountToCache(account);
            }
          }
          break;
      }
    }
    window.localStorage.setItem(INTRO_DONE, '1');
    window.routerHistory.push('/login');

    return {success: true};
  };

  const isAccountAlreadyLoggedIn = (cacheData: OrganizationAccountsCacheData | null, account: Account) => {
    return cacheData?.savedOrganizations.some((org) => org.user.id === account.id && org.accessToken);
  };

  const isSSOLogin = (account: Account, orgUrl: string) => {
    return account.loginMethods.includes(OrgLoginMethods.SSO) && account.organization.url === orgUrl;
  };

  const isOTPLogin = (account: Account) => {
    return account.loginMethods.includes(OrgLoginMethods.OTP);
  };

  const attemptLogin = async (
    account: Account,
    emailToken: string,
    isSingleAccount?: boolean,
    scope?: OrgLoginMethods,
  ) => {
    const otpExchangeToken = await AuthHelper.exchangeToken(account.organization.url, emailToken, scope);

    if (otpExchangeToken?.data?.error || otpExchangeToken?.error) {
      return {error: otpExchangeToken?.data?.error_description || otpExchangeToken?.error};
    }

    if (otpExchangeToken?.data) {
      const authData = convertAuthResponseToAuthDTO(otpExchangeToken.data);
      saveLoggedInAccountToCache(account, authData, authData.user?.email || '', isSingleAccount);
    }
  };

  const saveNotLoggedInAccountToCache = (incomingAccount: Account) => {
    let cacheData = getCacheData();

    const newOrganizationData = createOrganizationData(incomingAccount);
    cacheData.savedOrganizations.unshift(newOrganizationData);

    updateCacheData(cacheData);
  };

  const saveLoggedInAccountToCache = (
    incomingAccount: Account,
    payload: any,
    email: string,
    isSingleAccount?: boolean,
  ) => {
    let cacheData = getCacheData();
    const incomingUserId = payload.user.id;

    const existingOrgIndex = findExistingOrganizationIndex(
      cacheData.savedOrganizations,
      incomingAccount.organization.name,
      incomingUserId,
    );

    if (existingOrgIndex !== -1) {
      cacheData.savedOrganizations[existingOrgIndex] = {
        ...cacheData.savedOrganizations[existingOrgIndex],
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
      };
    } else {
      cacheData.savedOrganizations.unshift({
        ...payload,
        organization: incomingAccount.organization,
      });
    }

    cacheData.selectedAccountUserId = isSingleAccount ? incomingAccount.id : '';
    updateCacheData(cacheData);
  };

  const getCacheData = (): OrganizationAccountsCacheData => {
    return (
      localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA) ?? {
        savedOrganizations: [],
        selectedAccountUserId: '',
      }
    );
  };

  const createOrganizationData = (account: Account) => {
    return {
      accessToken: null,
      accessTokenExpiresAt: null,
      refreshToken: null,
      organization: account.organization,
      refreshTokenExpiresAt: null,
      scopeToken: '',
      sessionID: '',
      user: {
        id: account.id,
        firstname: account.firstName,
        lastname: account.lastName,
        email: account.email,
      },
    };
  };

  const findExistingOrganizationIndex = (organizations: any[], organizationName: string, userId: string) => {
    return organizations.findIndex((org) => org.organization.name === organizationName && org.user.id === userId);
  };

  const updateCacheData = (cacheData: OrganizationAccountsCacheData) => {
    window.localStorage.setItem(ORGANIZATION_ACCOUNTS_DATA, JSON.stringify(cacheData));
  };

  const notifyNativeAppOfAccountsFetched = (cacheData: OrganizationAccountsCacheData) => {
    callNative(WebViewCallBacks.ACCOUNTS_FETCHED, cacheData);
  };

  const handleResendOTP = async (email: string) => {
    const res = await requestAddressOTP({address: email, type: 'email'});

    console.log(res, 'res');
    if (typeof res === 'string') {
      return {challengeId: res};
    }

    if (res.error) {
      return {error: true};
    }
  };

  return {
    enterEmail,
    submitOTP,
    handleAccountDiscoveryAfterSSO,
    decodeSavedAccountString,
    handleSaveAccountsQueryParamToCache,
    handleLoginToExistingAccountsFromSignup,
    handleResendOTP,
  };
};
