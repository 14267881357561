import React, {useEffect} from 'react';
import {HYPERCARE_REGION} from '../constants/storageKeys';
import {
  REGION_CODE_PARAM_OVERRIDE,
  UNITED_STATES_ABBREVIATION,
  UNITED_STATES_ABBREVIATION_2,
} from '../constants/strings';

export const useRegionParamOverride = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const regionCodeOverride = params.get(REGION_CODE_PARAM_OVERRIDE)?.toUpperCase() || '';

    if (regionCodeOverride) {
      const region =
        regionCodeOverride === UNITED_STATES_ABBREVIATION_2 ? UNITED_STATES_ABBREVIATION : regionCodeOverride;

      window.localStorage.setItem(HYPERCARE_REGION, region);
    }
  }, []);

  return null;
};
