import React from 'react';

export const LinkIcon = () => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_447_7659)">
      <path
        d="M18.2812 4.70257C17.9328 4.35297 17.5188 4.07558 17.063 3.88631C16.6072 3.69704 16.1185 3.59961 15.6249 3.59961C15.1314 3.59961 14.6427 3.69704 14.1869 3.88631C13.731 4.07558 13.3171 4.35297 12.9687 4.70257L13.8562 5.59007C14.0889 5.35738 14.3651 5.1728 14.6691 5.04688C14.9731 4.92095 15.299 4.85613 15.6281 4.85613C15.9571 4.85613 16.283 4.92095 16.587 5.04688C16.891 5.1728 17.1672 5.35738 17.3999 5.59007C17.6326 5.82275 17.8172 6.09899 17.9431 6.40301C18.0691 6.70703 18.1339 7.03287 18.1339 7.36194C18.1339 7.69101 18.0691 8.01685 17.9431 8.32087C17.8172 8.62489 17.6326 8.90113 17.3999 9.13382L12.3999 14.1338C11.9308 14.6037 11.2943 14.8681 10.6303 14.8687C9.96627 14.8693 9.32924 14.606 8.8593 14.1369C8.38937 13.6678 8.12504 13.0313 8.12445 12.3673C8.12387 11.7033 8.38708 11.0662 8.85618 10.5963L9.73743 9.70882L8.85618 8.82132L7.96868 9.70882C7.61908 10.0572 7.34169 10.4712 7.15242 10.927C6.96315 11.3828 6.86572 11.8715 6.86572 12.3651C6.86572 12.8586 6.96315 13.3473 7.15242 13.8031C7.34169 14.259 7.61908 14.6729 7.96868 15.0213C8.67586 15.7194 9.63122 16.1083 10.6249 16.1026C11.1204 16.1046 11.6113 16.0084 12.0694 15.8196C12.5275 15.6308 12.9436 15.3531 13.2937 15.0026L18.2937 10.0026C18.9943 9.29779 19.3865 8.34373 19.3841 7.34998C19.3818 6.35623 18.9851 5.40404 18.2812 4.70257Z"
        fill="#4A4A4A"
      />
      <path
        d="M2.61879 15.9904C2.38541 15.7581 2.20022 15.482 2.07386 15.1779C1.94749 14.8739 1.88244 14.5478 1.88244 14.2185C1.88244 13.8893 1.94749 13.5632 2.07386 13.2591C2.20022 12.9551 2.38541 12.679 2.61879 12.4467L7.61879 7.44667C7.85109 7.21329 8.1272 7.0281 8.43127 6.90173C8.73534 6.77537 9.06138 6.71032 9.39067 6.71032C9.71995 6.71032 10.046 6.77537 10.3501 6.90173C10.6541 7.0281 10.9302 7.21329 11.1625 7.44667C11.3944 7.68079 11.577 7.95908 11.6994 8.26505C11.8218 8.57102 11.8815 8.89844 11.875 9.22792C11.8769 9.55842 11.8133 9.88602 11.6878 10.1918C11.5623 10.4975 11.3774 10.7753 11.1438 11.0092L9.81879 12.3529L10.7063 13.2404L12.0313 11.9154C12.7366 11.2101 13.1328 10.2535 13.1328 9.25604C13.1328 8.25858 12.7366 7.30198 12.0313 6.59667C11.326 5.89136 10.3694 5.49512 9.37192 5.49512C8.37446 5.49512 7.41785 5.89136 6.71254 6.59667L1.71254 11.5967C1.362 11.9452 1.08382 12.3595 0.893994 12.8159C0.704168 13.2723 0.606445 13.7617 0.606445 14.256C0.606445 14.7503 0.704168 15.2398 0.893994 15.6962C1.08382 16.1526 1.362 16.5669 1.71254 16.9154C2.42431 17.6082 3.38185 17.9903 4.37504 17.9779C5.37698 17.9789 6.33862 17.5834 7.05004 16.8779L6.16254 15.9904C5.93025 16.2238 5.65413 16.409 5.35006 16.5354C5.04599 16.6617 4.71995 16.7268 4.39067 16.7268C4.06138 16.7268 3.73534 16.6617 3.43127 16.5354C3.1272 16.409 2.85109 16.2238 2.61879 15.9904Z"
        fill="#4A4A4A"
      />
    </g>
    <defs>
      <clipPath id="clip0_447_7659">
        <rect width="20" height="20" fill="white" transform="translate(0 0.477539)" />
      </clipPath>
    </defs>
  </svg>
);
