import gql from 'graphql-tag';

export const FETCH_ACCOUNTS_QUERY = gql`
  query GetAccounts {
    accounts {
      __typename
      ... on QueryAccountsResultSuccess {
        accounts {
          id
          firstName
          lastName
          memberStatus
          loginMethods
          organization {
            name
            url
            id
            isOpenDoor
            loginMethods
            privacyPolicyURL
            termsOfServiceURL
          }
        }
      }
    }
  }
`;
